<template>
    <div>
        <h1 class="text-3xl font-bold text-black pb-6">ISMGA KELGAN TAKLIFLAR</h1>

        <div class="flex justify-end mb-2">
            <button @click="backToPathName" class="flex items-center px-4 text-xl py-2 hover:bg-gray-200 text-gray-500 text-sm font-medium rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 9.75L14.25 12m0 0l2.25 2.25M14.25 12l2.25-2.25M14.25 12L12 14.25m-2.58 4.92l-6.375-6.375a1.125 1.125 0 010-1.59L9.42 4.83c.211-.211.498-.33.796-.33H19.5a2.25 2.25 0 012.25 2.25v10.5a2.25 2.25 0 01-2.25 2.25h-9.284c-.298 0-.585-.119-.796-.33z" />
                </svg>
                Qaytish
            </button>
        </div>
        <table class="min-w-full">
            <thead>
                <tr>
                    <th class="px-4 py-2 w-1/6">#</th>
                    <th class="px-4 py-2 w-1/2">Offers</th>
                    <th class="px-4 py-2 w-1/2">Status</th>
                    <th class="px-4 py-2 w-1/2">Deleted</th>
                    <th class="px-4 py-2 w-1/2">createdDate</th>
                    <th class="px-4 py-2 w-1/2">lastModifiedDate</th>
                    <th class="px-4 py-2 w-1/2">Actions</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(offer, index) in nameOffers" :key="index">
                    <td class="px-4 py-2">{{ index + 1 }}</td>
                    <td class="px-4 py-2">{{ offer.desc }}</td>
                    <td class="px-4 py-2">
                        <span :class="{
                            'text-yellow-500 font-bold': offer.status == 'INITIAL',
                            'text-green-500 font-bold': offer.status == 'ACCEPTED',
                            'text-red-500 font-bold': offer.status == 'REJECTED',
                        }">
                            {{ offer.status }}
                        </span>
                    </td>
                    <td class="px-4 py-2">

                        <span class="flex flex-inline justify-center items-center">
                            <div class="h-2.5 w-2.5 rounded-full mr-2"
                                :class="offer.deleted ? 'bg-red-500' : 'bg-green-500'">
                            </div>{{ offer.deleted }}
                        </span>
                    </td>
                    <td class="px-4 py-2">{{ getCreatedDate(offer.createdDate) }}</td>
                    <td class="px-4 py-2">{{ getCreatedDate(offer.lastModifiedDate) }}</td>
                    <td class="px-4 py-2">
                        <span class="flex flex-inline justify-center items-center">
                            <div @click="updateOffer(offer.id)" role="button"
                                class="font-medium text-blue-600 dark:text-yellow-500 hover:underline">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>
                            </div>
                            <div role="button" class="font-medium text-blue-600 dark:text-red-500 hover:underline"
                                @click="showDeleteModal(offer)">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                </svg>
                            </div>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <DeleteModal :deleteModalShow="isModalShow" :deleteItem="deleteItem" @confirmation="deleteOffer"
            @cancel="isModalShow = !isModalShow" />
    </div>
</template>

<script>
import DeleteModal from '@/components/DeleteModal.vue';
import axios from '@/axiosInstance';
export default {
    name: 'NameOffersVue',
    components: {
        DeleteModal,
    },
    data() {
        return {
            nameOffers: [],
            isModalShow: false,
            deleteItem: {}
        };
    },
    computed: {
        getCreatedDate() {
            return (time) => {
                const date = new Date(time);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear()).slice(2);
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');

                return `${day}-${month}-${year} ${hours}:${minutes}`;
            }
        }
    },
    created() {
        this.getOffers()
    },
    methods: {
        getOffers() {
            const { id } = this.$route.query
            axios.get(`/api/names/v1/offers?id=${id}`)
                .then(response => {
                    this.nameOffers = response.data
                }).catch(error => { console.log(error) })
        },
        showDeleteModal(offerObj) {
            this.deleteItem = offerObj;
            this.isModalShow = !this.isModalShow;
        },
        updateOffer(id) {
            console.log(id)
            this.$router.push({ name: 'update-offer', query: { id: id } })
        },
        async deleteOffer(id) {
            try {
                await axios.delete(`/api/offers/v1/${id}`);
                this.getOffers()
                this.isModalShow = !this.isModalShow;
            } catch (error) {
                console.log(error);
            }

        },
        backToPathName() {
            this.$router.go(-1)
        }
    },
};
</script>

<style scoped>
table {
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #e2e8f0;
}

td {
    border-bottom: 1px solid #e2e8f0;
}
</style>