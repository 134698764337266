<template>
    <div>
        <section class="h-screen">
            <div class="container py-4">
                <BackspaceButton />
                <!-- Left column container with background-->
                <div class="g-6 pt-10 flex h-full flex-wrap items-center justify-center lg:justify-between">
                    <div class="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
                        <img src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                            class="w-full" alt="Sample image" />
                    </div>

                    <!-- Right column container -->
                    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
                        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                            <!-- <img class="mx-auto h-10 w-auto"
                                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600" alt="Your Company" /> -->
                            <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 flex items-center justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="w-6 h-6 mr-4">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                                </svg>
                                Admin panelga kirish
                            </h2>
                        </div>

                        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                            <form class="space-y-6">
                                <div>
                                    <div class="flex items-center justify-between">
                                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Elektron pochta: </label>
                                    </div>
                                    <div class="mt-2">
                                        <input v-model="isAdmin.email" id="email" name="email" type="email" autocomplete="email"
                                            placeholder="name@company.com" required=""
                                            class="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>

                                <div>
                                    <div class="flex items-center justify-between">
                                        <label for="password"
                                            class="block text-sm font-medium leading-6 text-gray-900">Parol</label>
                                        <!-- <div class="text-sm">
                                            <a href="#" class="font-semibold text-indigo-600 hover:text-indigo-500">Forgot
                                                password?</a>
                                        </div> -->
                                    </div>
                                    <div class="mt-2">
                                        <input v-model="isAdmin.pass" id="password" name="password" type="password" autocomplete="current-password"
                                            placeholder="••••••••" required=""
                                            class="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                    </div>
                                </div>
                                <div class="text-red-600">{{ notify }}</div>
                                <div>
                                    <button @click="checkAdmin"
                                        class="flex w-full justify-center rounded-md bg-indigo-600 p-3 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Kirish</button>
                                </div>
                            </form>
                            <!-- 
                            <p class="mt-10 text-center text-sm text-gray-500">
                                Not a member?
                                {{ ' ' }}
                                <a href="#" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Start a 14
                                    day free trial</a>
                            </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import BackspaceButton from '../BackspaceButton.vue';
import {signin} from '@/store/eventBus'
export default {
    name: 'LoginAdmin',
    components:{
        BackspaceButton,
    },
    data() {
        return {
            isAdmin : {
                email : '',
                pass : ''
            }, 
            notify: ''
        };
    },


    methods: {
        checkAdmin(event){
            
            if ((this.isAdmin.email == signin.log['login']) && (this.isAdmin.pass == signin.log['pswd'])){
                this.$router.push({name:'dashboard'})
                localStorage.setItem('isHaveAdmin', true)
            }else if(this.isAdmin.email && this.isAdmin.pass){
                event.preventDefault()
                this.notificationWarning()
            }else{
                console.log('enter data!')
            }
        },
        notificationWarning() {
            setTimeout(() => {
                this.notify = 'Email yoki parol noto\'g\'ri kiritildi!';
            }, 100);
            this.notify = ''
            // event.preventDefault();
        },
    },
};
</script>

<style lang="scss" scoped></style>