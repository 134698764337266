<template>
    <div>
        <div class="pb-4">
            <p class="text-lg font-normal lg:text-xl text-gray-400 mx-4">Foydalanuvchilar uchun qulay bo`lish maqsadida,
                saytimizda ismlar kelib chiqishiga asoslangan holda, turli xil ruknlarga bo`lingan. Rukunlar ismlarni oson
                saralashga imkon beradi. Siz istagan ismni qayerga va kimga tegishli ekanini aniqlashingiz mumkin</p>
        </div>
        <div class="grid lg:grid-cols-8 md:grid-cols-5 sm:grid-cols-3 grid-cols-2">
            <div v-for="(items, index) in rukns" :key="index">
                <button @click="goToRukns(items)"
                    class="relative inline-flex items-center justify-start p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white text-white focus:ring-4 focus:outline-none ring-cyan-200 focus:ring-cyan-800">
                    <div
                        class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-md group-hover:bg-opacity-0">
                        <span v-for="item in items" :key="item.id">
                            {{ item }}
                        </span>
                    </div>
                </button>

            </div>
            <div class="relative">
                <button v-if="!showAll" @click="showAllRukns" class="py-2 px-4 hover:text-white border border-1 rounded border-cyan-500 hover:bg-gradient-to-br from-cyan-500 to-blue-500 font-bold"> +{{ length-13 }}</button>
            </div>
        </div>


    </div>
</template>

<script>
import { eventBus } from '@/store/eventBus';
export default {
    name: 'RuknsVue',
    props: {
        gender: String,
    },

    data() {
        return {
            rukns: [],
            showAll: false,
            length: 0,
        };
    },

    created() {
        this.rukns = Object.values(eventBus.rukns).slice(0, 13);
        this.length = Object.values(eventBus.rukns).length;
        console.log(Object.values(eventBus.rukns).length);
    },


    methods: {
        showAllRukns() {
            this.showAll = true;
            this.rukns = Object.values(eventBus.rukns);
        },

        async goToRukns(rukn_name) {
            rukn_name = Object.keys(eventBus.rukns).find(key => eventBus.rukns[key] == rukn_name)
            if (!this.gender) {
                this.$router.push({ name: 'names-list', query: { rukn: rukn_name } });
            } else {
                this.$router.push({ name: 'names-list', query: { gender: this.gender, rukn: rukn_name } });
            }
        }
    },
};
</script>

<style scoped></style>