<template>
    <div>
        <NotificationVue :notify="notify" :status="status" />
        <h1 class="text-xl font-bold mb-4">MAQOLANI TAHRIRLASH</h1>
        <div class="container p-10 w-3/4 space-y-4 text-left">
            <div class="mb-4">
                <label for="large-input" class="block mb-2 text-md font-medium text-gray-900">*Mavzu</label>

                <input v-model="data.title" type="text" id="small-input" required
                    class="block w-full p-3 text-gray-900 border border-gray-300 rounded-lg text-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Write an title of article...">
            </div>
            <div class="mb-4">
                <label for="large-input" class="block mb-2 text-md font-medium text-gray-900">*Sarlavha</label>

                <input v-model="data.desc" type="text" id="small-input" required
                    class="block w-full p-3 text-gray-900 border border-gray-300 rounded-lg text-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Write an subtitle of article...">
            </div>
            <div class="mb-4">
                <label for="message" class="block mb-2 text-md font-medium text-gray-900">*Asosiy qism <span
                        class="font-bold"></span></label>
                <input v-model="data.author" type="text" id="small-input" required
                    class="mb-2 block w-full p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Author of article">
                <textarea v-model="data.text" id="message" rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Write your thoughts here..."></textarea>

            </div>
            <label for="message" class="block mb-2 text-md font-medium text-gray-900 tavsiya">Maqolani yuklash.
                    FORMAT<span class="font-bold text-red-500"> *.pdf </span></label>
                <input @change="uploadDocument" type="file" style="margin: 0;">
            <div v-if="data.imageHashId" class="relative inline-block w-full">
                <img :src="getImageByHashId(data.imageHashId)" alt="article-photo" class="w-full h-auto rounded">
                <div
                    class="absolute top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50 flex items-center justify-center opacity-0 transition-opacity duration-300">
                    <button @click="deleteImage"
                        class="flex text-lg text-white bg-red-500 hover:bg-red-600 rounded px-4 py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </button>
                </div>
            </div>
            <!-- <div v-else-if="imageField" class="flex flex-inline justify-between items-center">
                    <div class="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6 mr-4">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                        </svg>

                        {{ imageField.name }}
                    </div>
                    <div>
                        {{ imageField.size / 1024 }} KB
                    </div>
                    <div>
                        {{ imageField.type }}
                    </div>
                    <span class="flex justify-between space-x-2">

                        <div role="button" class="font-medium text-blue-600 dark:text-red-500 hover:underline"
                            @click="deleteImage">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                        </div>
                        <div role="button" class="font-medium text-blue-600">
                            <label for="dropzone-file"
                                class="flex flex-col items-center justify-center w-full h-30 cursor-pointer  bg-white">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                    stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                </svg>

                                <input @change="handleFileUpdate" id="dropzone-file" type="file" ref="files"
                                    class="hidden" />
                            </label>
                        </div>
                    </span>
                </div> -->
            <div v-else class="flex items-center justify-center w-full">
                <label for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer  bg-white">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                            </path>
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to
                                upload</span> or drag and drop</p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                    </div>
                    <input @change="handleFileUpload" id="dropzone-file" type="file" ref="files" class="hidden" />
                </label>
            </div>
            <br>
            <div class="mb-6">
                <input v-model="data.deleted" id="link-checkbox" type="checkbox" value="true"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                <label for="link-checkbox" class="ml-2 text-md font-medium text-gray-900">I agree the name is
                    <span class="bg-yellow-400 text-white font-bold px-2">DELETED</span> </label>
            </div>

            <div class="space-x-2">
                <button @click="updateArticle"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mr-2">Saqlash</button>
                <button @click="showDeleteModal"
                    class="text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center  mr-2">O'chirish</button>
            </div>
        </div>
        <DeleteModal :deleteModalShow="isModalShow" :deleteItem="data" @confirmation="deleteArticle"
            @cancel="isModalShow = !isModalShow" />

    </div>
</template>

<script>
import axios from '@/axiosInstance';
import NotificationVue from '@/components/Notification.vue';
import DeleteModal from '@/components/DeleteModal.vue';
export default {
    name: 'CreateArticle',
    components: {
        NotificationVue,
        DeleteModal,
    },
    data() {
        return {
            imageField: null,
            imageId: '',
            documentField: null,

            data: {},
            isDataValid: false,
            notify: '',
            status: '',
            isModalShow: false,
        }
    },
    created() {
        console.log(this.$route.query.articleId)
        axios.get(`/api/articles/v1/get/${this.$route.query.articleId}`).then(response => {
            this.data = response.data;
            console.log(this.data)
        }).catch(error => console.log(error))
    },
    computed: {
        getImageByHashId() {
            return (id) => {
                const imagePath = `/api/files/v1/get/${id}`
                return axios.defaults.baseURL + imagePath
            }
        }
    },
    methods: {
        uploadDocument(event) {
            this.documentField = event.target.files[0]
            console.log(this.data.articleHashId);
            // console.log(this.documentField.type, typeof this.documentField.type)

            if (this.documentField.type.includes('pdf')) {
                axios.delete(`/api/files/v1/delete/${this.data.articleHashId}`);

                const formData = new FormData();
                
                formData.append('file', this.documentField)
                document.getElementsByClassName("tavsiya")[0].innerHTML = '';

                axios.post(`/api/files/v1/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    this.data.articleHashId = response.data
                    // this.imagrURl = `http://localhost:8080/api/files/v1/get/${this.imageId}`
                    console.log(response.data);
                }).catch(error => console.log(error))
            } else {
                document.getElementsByClassName("tavsiya")[0].style.color = 'red'
                document.getElementsByClassName("tavsiya")[0].innerHTML = 'Bu Fayl PDF da emas! Iltimos Faylni qayta yuklang.';

            }


        },
        handleFileUpload(event) {
            this.imageField = event.target.files[0]

            console.log(this.imageField)

            const formData = new FormData();
            
            formData.append('file', this.imageField)

            axios.post(`/api/files/v1/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                this.imageId = response.data
                this.data.imageHashId = this.imageId
                this.imagrURl = `http://localhost:8080/api/files/v1/get/${this.imageId}`
                console.log(response.data);
            }).catch(error => console.log(error))
        },
        updateArticle(event) {
            //for save button
            event.preventDefault();
            if (this.data.imageHashId && this.data.articleHashId) {
                this.isDataValid = this.data.title.trim() !== '' && this.data.text.trim() !== '' && this.data.desc.trim() !== '' && this.data.author.trim() !== ''
            } else {
                this.notificationWarning('Rasm topilmadi!', 'Red')
            }
            if (this.isDataValid) {
                axios.put(`/api/articles/v1/${this.data.id}`, this.data).then(response => {
                    console.log(response.data);
                    if (response.status == 200) {
                        this.notificationWarning(`O'zgarishlar saqlandi. Natija: ${response.status}`, 'green')
                        console.log(response.data, typeof response.data);
                        setTimeout(() => {
                            this.$router.go(-1)
                        }, 1000)
                    } else {
                        this.notificationWarning(`Kutilmagan xatolik!`, 'yellow')
                    }
                }).catch(error => console.log(error))
                // this.$router.push({ name: 'settings' })
            } else {
                this.notificationWarning('Ma\'lumot yaroqli emas!', 'yellow')
            }
        },
        // handleFileUpdate(event) {
        //     this.imageField = event.target.files[0]
        //     // this.imageField = this.$refs.imageInput.files[0];

        //     console.log(this.imageField)

        //     const formData = new FormData();
        //     formData.append('hashId', this.imageId)
        //     formData.append('file', this.imageField)

        //     axios.put(`/api/files/v1/update`, formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     }).then(response => {
        //         this.imageId = response.data
        //         // this.imagrURl = `http://localhost:8080/api/files/v1/get/${this.imageId}`
        //         console.log(response.data);
        //     }).catch(error => console.log(error))
        // },

        deleteImage() {
            //for imageField
            axios.delete(`/api/files/v1/delete/${this.data.imageHashId}`);
            this.imageField = null;
            this.data.imageHashId = null
            // this.imagrURl = `http://localhost:8080/api/files/v1/get/${this.imageId}`;
            // this.$router.go(0)
        },
        deleteArticle() {
            // console.log('article is deleted ')
            axios.delete(`/api/articles/v1/${this.data.id}`).then(response=>{
                if(response.status == 200){
                    this.notificationWarning(`Maqola o'chirildi! Natija ${response.status}`, 'green')
                    setTimeout(() => {
                            this.$router.go(-1)
                        }, 1000)
                }
                }
            )
        },
        showDeleteModal() {
            this.isModalShow = !this.isModalShow;
        },

        notificationWarning(text = '', status = '') {
            this.status = status
            this.notify = text
            setTimeout(() => {
                this.notify = ''
            }, 2000);
        },

    },
};
</script>

<style scoped>
.relative:hover .absolute {
    opacity: 1;
}
</style>