<template>
    <div class="bg-white px-4">
        <section>
            <h1 class="text-3xl text-left text-black py-6">Bosh sahifa</h1>

        </section>

        <div class="grid sm:grid-cols-3 gap-5">
            <router-link :to="{ name: 'names' }"
                class="bg-gray-100 px-8 py-8 flex justify-between items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                <div>
                    <span class="text-5xl leading-none"></span>
                    <p class="mt-2 text-gray-500 font-700">Ismlar ro'yhati</p>
                    <div class="text-3xl leading-none text-left">{{ namesData.length }}</div>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-12 h-12 text-green-500">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                    </svg>

                </div>
            </router-link>
            <router-link :to="{ name: 'offers' }"
                class="bg-gray-100 px-8 py-8 flex justify-between items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                <div>
                    <span class="text-5xl leading-none"></span>
                    <p class="mt-2 text-gray-500 font-700">Takliflar</p>
                    <div class="text-3xl leading-none text-left">{{ offersData.length }}</div>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-12 h-12 text-orange-500">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                    </svg>
                </div>
            </router-link>
            <router-link :to="{ name: 'articles' }"
                class="bg-gray-100 px-8 py-8 flex justify-between items-center text-center lg:transform hover:scale-110 hover:shadow-lg transition-transform duration-200">
                <div>
                    <span class="text-5xl leading-none"></span>
                    <p class="mt-2 text-gray-500 font-700">Maqolalar</p>
                    <div class="text-3xl leading-none text-left">{{ articlesData.length }}</div>
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-12 h-12 text-cyan-500">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46" />
                    </svg>

                </div>
            </router-link>
        </div>
        <div class="g-6 pt-10 px-4 flex h-full flex-wrap items-center justify-center lg:justify-between">
            <div class="shrink-1 pl-4 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
                <img v-if="imageUrl" :src="imageUrl" width="300" alt="from server image">
                <img v-else src="../../../assets/admin/admin.png" width="300"
                    alt="Admin image" />
                
            </div>

            <!-- Right column container -->
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-6 lg:px-8">
                <div class="sm:mx-auto sm:w-full sm:max-w-sm">
                   <h2
                        class="mt-10 text-left text-md font-bold leading-9 tracking-tight text-gray-900 flex items-center justify-start">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6 mr-2">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>

                        Admin Profile
                    </h2>
                </div>

                <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <div class="space-y-6">
                        <div>
                            <div class="flex items-center justify-between">
                                <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Ismi</label>
                            </div>
                            <div class="mt-2">
                                <input id="name" name="name" type="text" autocomplete="current-password" readonly
                                    :value="admin['name']"
                                    class="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                        </div>
                        <div>
                            <div class="flex items-center justify-between">
                                <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Elektron pochta
                                    </label>
                            </div>
                            <div class="mt-2">
                                <input id="email" name="email" type="email" autocomplete="email"
                                    readonly :value="admin['login']"
                                    class="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                            </div>
                        </div>

                        
                        <div class="text-red-600"></div>

                    </div>
                    <!-- 
                            <p class="mt-10 text-center text-sm text-gray-500">
                                Not a member?
                                {{ ' ' }}
                                <a href="#" class="font-semibold leading-6 text-indigo-600 hover:text-indigo-500">Start a 14
                                    day free trial</a>
                            </p> -->
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import axios from '@/axiosInstance';
import {signin} from '@/store/eventBus';
export default {
    name: 'MeaningOfNamesAdminLanding',

    data() {
        return {
            namesData: [],
            offersData: [],
            articlesData: [],
            admin: signin.log,
            imageUrl:'',

        };
    },

    created() {
        axios.get('http://213.230.110.100:8090/api/files/v1/get/f8916277-c61e-48a9-9730-0b0481a7a043').then(response=>{
            if(response.status == 200){
                this.imageUrl = "http://213.230.110.100:8090/api/files/v1/get/f8916277-c61e-48a9-9730-0b0481a7a043";
            } else {
                this.imageUrl = '';
            }
        }).catch(error=> console.log(error))
        axios.get('/api/names/v1').then(response => {
            this.namesData = response.data
        })
        axios.get('/api/names/v1/offer_counts').then(response => {
            this.offersData = response.data
            console.log(this.offersData)
        })
        axios.get('/api/articles/v1').then(response => {
            this.articlesData = response.data
        })

    },

    methods: {

    },
};
</script>

<style scoped></style>