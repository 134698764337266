<template>
    <div class="p-4 lg:max-w-7xl lg:mx-auto pb-36">
        <BackspaceButton/>
        <h1
            class="mt-8 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-dark">
            <span :class="name.gender=='WOMEN' ? 'text-red-500':'text-blue-500'">{{name.latinName}}</span> ismiga qo'shimcha ma'lumotlar
        </h1>
        <div class="p-4 rounded-2xl flex my-4" :class="name.gender=='WOMEN' ? 'bg-red-100':'bg-blue-100'">
            <div class="text-yellow-500 text-2xl pr-4">
                <i class="fas fa-circle-info"></i>
            </div>
            <div>
                Ushbu forma orqali siz <span class="font-bold">{{name.latinName}}</span> ismiga oid qo‘shimcha ma'lumotlarni
                jo‘natishingiz mumkin. Jo‘natilgan ma'lumot o‘rganib chiqiladi va to‘g‘riligi tekshirilgach saytga
                qo‘shiladi. </div>
        </div>

            <div class="mb-4">
                <textarea v-model="desc" required class="block w-full pl-4 pr-12 rounded-2xl bg-yellow-100 focus:bg-white"
                    cols="30" rows="5" placeholder="Ismining ma'nosi"></textarea>
            </div>

            <div class="mb-4">
                <textarea v-model="src" required 
                    class="block w-full pl-4 pr-12 rounded-2xl bg-yellow-100 border-0 focus:ring-gray-200 focus:bg-white"
                    cols="30" rows="2"
                    placeholder="Manba, ya'ni ismning ma'nosi haqidagi ma'lumot qaerdan olingan"></textarea>
            </div>

            <div class="mb-4">
                <textarea v-model="contact" required
                    class="block w-full pl-4 pr-12 rounded-2xl bg-yellow-100 border-0 focus:ring-gray-200 focus:bg-white"
                    cols="30" rows="2"
                    placeholder="Siz bilan qanday bog‘lanishimiz mumkin: email, telefon va h.k."></textarea>
            </div>
            <div class="flex justify-end space-x-2">
                <button @click="postOffer(name.id)" class="px-4 py-2 text-white rounded-full font-bold border-0 w-1/5 end" :class="name.gender=='WOMEN' ? ' bg-red-500 ':' bg-blue-500 '">
                    Yuborish </button>
                
            </div>


        </div>
</template>

<script>
import axios from '@/axiosInstance.js';
import BackspaceButton from '../BackspaceButton.vue';
export default {
    name: 'OffersVue',
    components:{
        BackspaceButton,
    },

    data() {
        return {
            name: {}, 
            desc: '',
            src : '',
            contact : '',
        };
    },

    mounted() {
        axios.get(`/api/names/v1/get/${this.$route.query.name}`)
        .then(response=>{this.name = response.data})
        .catch(error=>{console.log(error)})
    },
    created() {
    },

    methods: {
        postOffer(nameId){
            axios.post('/api/offers/v1/add', {
                nameId: nameId,
                desc: this.desc + " " + this.src + " " + this.contact,
                deleted:"false",
                status:"INITIAL",
                createdDate:"0",
                lastModifiedDate:"0"
            }).then(response=>{
                console.log(response.data)
                if(response.status == '200'){
                    setTimeout(()=>{this.$router.go(-1)}, 500)
            }}).catch(error=>
                console.log(error))
        },
        
    },
};
</script>

<style scoped></style>