import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
    state:{
        seach_item : "",
        // rukns: {
        //     "ARAB": ['arabcha'],
        //     "UZBEK": ["o'zbekcha"],
        //     "TURK": ['turkcha'],
        //     "YUNON": ['yunoncha'],
        //     "SUGDCHA": ["so'g'dcha"],
        //     "QADIMIY_YAHUD": ['qadimiy yahudiycha'],
        //     "FORS_TOJIK": ['fors-tojikcha'],

        //     "ARAB_UZBEK": ["arabcha", "o'zbekcha"],
        //     "ARAB_FORS_TOJIK": ['arabcha', 'fors-tojikcha'],
        //     "ARAB_YUNON": ['arabcha', 'yunoncha'],
        //     "ARAB_HIND": ['arabcha', 'hindcha'],

        //     "UZBEK_FORS_TOJIK": ["o'zbekcha", "fors-tojikcha"],
        //     "UZBEK_HIND": ["o'zbekcha", "hindcha"],

        //     "FORS_TOJIK_HIND": ["fors-tojikcha", "hindcha"],
        //     "FORS_TOJIK_QADIMIY_YUNON": ["fors-tojikcha", "qadimiy yunoncha"],
        //     "FORS_TOJIK_QADIMIY_ERON": ["fors-tojikcha", "qadimiy eroncha"],

        //     "YUNON_UZBEK": ["yunoncha", "o'zbekcha"],
        //     "YUNON_FORS_TOJIK": ["yunoncha", "fors-tojikcha"],

        //     "QADIMIY_YAHUD_UZBEK": ["qadimiy yahudiycha", "o'zbekcha"],
        //     "QADIMIY_YAHUD_ARAB": ["qadimiy yahudiycha", "arabcha"],

        //     "QADIMIY_YUNON_FORS_TOJIK": ["qadimiy yunoncha", "fors-tojikcha"]
        // },
    },
    mutations:{
        setSearchItem(state, query){
            state.seach_item = query;
        }
    },
    actions:{
        // setSearchItem({ commit }, item) {
        //     commit('setSearchItem', item);
        // },
    },
    getters:{
        // getSearchItem: state => state.search_item,
    },
})

export default store;