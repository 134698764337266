<template>
    <div class="p-4 lg:max-w-7xl lg:mx-auto pb-36">
        <!-- Header -->
        <MiniNavbar/>

        <!-- if it is Boy -->
        <div v-if="genderType==='MEN'"
            class="h-60 relative rounded-xl bg-cover bg-center bg-no-repeat bg-[length:1280px_250px] bg-[url('./../../assets/Boybcg.png')]">
            <div class="absolute w-auto h-auto" style="transform:translate(-50%, -50%);left:50%; top:50%;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
                    x="0px" y="0px" viewBox="0 0 198.496 198.496"
                    style="enable-background:new 0 0 198.496 198.496; width:100%; height: 200px;" xml:space="preserve">
                    <g>
                        <g>
                            <rect x="84.55" y="148.231" style="fill:#FDCC9B;" width="29.395" height="32.922" />
                            <path style="fill:#FCBC85;"
                                d="M84.551,152.108c0,0,12.365,7.874,29.395,6.05v-9.928H84.551V152.108z" />
                            <ellipse style="fill:#FCBC85;" cx="42.164" cy="97.181" rx="14.343" ry="16.364" />
                            <ellipse style="fill:#FCBC85;" cx="156.332" cy="97.181" rx="14.343" ry="16.364" />
                            <path style="fill:#FDCC9B;"
                                d="M156.274,65.925c0-24.103-17.637-41.741-57.026-41.741c-39.389,0-57.026,17.637-57.026,41.741    c0,24.104-4.115,87.597,57.026,87.597C160.389,153.522,156.274,90.029,156.274,65.925z" />
                            <g>
                                <g>
                                    <ellipse style="fill:#3B2519;" cx="71.472" cy="93.262" rx="6.173" ry="6.761" />
                                    <circle style="fill:#FFFFFF;" cx="68.781" cy="90.277" r="1.846" />
                                </g>
                                <path style="fill:#51362A;"
                                    d="M61.597,76.507c2.919,1.46,7.606-4.96,18.335,0.625c1.956,1.018,3.123-8.708-8.377-8.708     C61.597,68.424,59.833,75.626,61.597,76.507z" />
                                <g>
                                    <ellipse style="fill:#3B2519;" cx="127.786" cy="93.262" rx="6.173" ry="6.761" />
                                    <circle style="fill:#FFFFFF;" cx="125.095" cy="90.277" r="1.846" />
                                </g>
                                <path style="fill:#51362A;"
                                    d="M136.899,76.507c-2.919,1.46-7.606-4.96-18.335,0.625c-1.956,1.018-3.123-8.708,8.378-8.708     C136.899,68.424,138.662,75.626,136.899,76.507z" />
                            </g>
                            <path style="fill:#FCBC85;"
                                d="M99.248,117.043c-6.1,0-9.774-4.556-9.774-2.352c0,2.205,1.764,6.394,9.774,6.394    c8.01,0,9.774-4.189,9.774-6.394C109.022,112.486,105.347,117.043,99.248,117.043z" />
                            <path style="fill:#FCBC85;"
                                d="M99.248,137.313c-2.11,0-3.381-1.576-3.381-0.813s0.61,2.211,3.381,2.211    c2.771,0,3.38-1.448,3.38-2.211S101.357,137.313,99.248,137.313z" />
                            <path style="fill:#F7945E;"
                                d="M99.248,131.696c-9.668,0-15.493-3.937-15.493-2.939c0,0.998,2.796,4.924,15.493,4.924    c12.697,0,15.493-3.926,15.493-4.924C114.74,127.759,108.916,131.696,99.248,131.696z" />
                        </g>
                        <path style="fill:#F7941E;"
                            d="M99.248,161.458v37.038H27.821C27.821,183.504,65.444,161.458,99.248,161.458z" />
                        <path style="fill:#F7941E;"
                            d="M99.248,161.458v37.038h71.427C170.675,183.504,133.052,161.458,99.248,161.458z" />
                        <path style="fill:#F36C21;"
                            d="M99.248,161.458c-7.19,0-14.552,1.005-21.689,2.72c0.048,0.063,7.916,10.214,21.689,10.214   c12.754,0,21.233-8.693,22.462-10.03C114.332,162.531,106.698,161.458,99.248,161.458z" />
                        <path style="fill:#FDCC9B;"
                            d="M84.55,162.74c0,0,4.299,5.332,14.697,5.332c10.398,0,14.698-5.332,14.698-5.332   S98.697,157.189,84.55,162.74z" />
                        <path style="fill:#51362A;"
                            d="M146.132,19.041c0,0-21.164-26.896-65.152-16.755C36.993,12.426,33.697,38.882,35.02,64.621   c1.323,25.74,4.85,40.84,9.022,38.974c4.172-1.867,2.001-18.857,2.442-22.778c0.441-3.921,4.409-21.653,31.162-19.007   c26.752,2.646,49.296-7.055,49.296-7.055s9.075,11.471,15.047,13.669c11.934,4.391,8.02,33.67,12.696,33.67   S182.288,34.473,146.132,19.041z" />
                    </g>

                </svg>
            </div>
        </div>
        <br>
        <!-- If it is Girl -->
        <div v-if="genderType==='WOMEN'"
            class="h-60 relative rounded-xl bg-cover bg-center bg-no-repeat bg-[length:1280px_250px] bg-[url('./../../assets/Girlbcg.png')]">
            <!-- <img src="./../../assets/Boybcg.png" alt="Landing image" class="object h-full w-full absolute top-0 left-0"> -->
            <div class="absolute w-auto h-auto" style="transform:translate(-50%, -50%);left:50%; top:50%;">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
                    x="0px" y="0px" viewBox="0 0 198.496 198.496"
                    style="enable-background:new 0 0 198.496 198.496; width:100%; height: 200px;" xml:space="preserve">
                    <g xmlns="http://www.w3.org/2000/svg">
                        <path style="fill:#543C2A;"
                            d="M101.867,66.139v131.395h60.26c0,0,11.449-59.365,11.168-95.113   C173.014,66.673,101.867,66.139,101.867,66.139z" />
                        <path style="fill:#543C2A;"
                            d="M101.867,66.139v131.395H41.609c0,0-11.449-59.365-11.168-95.113   C30.721,66.673,101.867,66.139,101.867,66.139z" />
                        <g>
                            <g>
                                <rect x="87.17" y="153.47" style="fill:#FDCC9B;" width="29.395" height="32.923" />
                                <path style="fill:#FCBC85;"
                                    d="M87.17,157.348c0,0,12.365,7.874,29.395,6.051v-9.929H87.17V157.348z" />
                                <ellipse style="fill:#FCBC85;" cx="44.784" cy="102.42" rx="14.343" ry="16.364" />
                                <ellipse style="fill:#FCBC85;" cx="158.952" cy="102.42" rx="14.343" ry="16.364" />
                                <path style="fill:#FDCC9B;"
                                    d="M158.894,71.165c0-24.104-17.637-41.741-57.026-41.741c-39.389,0-57.025,17.637-57.025,41.741     c0,24.104-4.115,87.597,57.025,87.597C163.009,158.762,158.894,95.269,158.894,71.165z" />
                                <g>
                                    <g>
                                        <ellipse style="fill:#3B2519;" cx="74.092" cy="98.502" rx="6.173" ry="6.761" />
                                        <circle style="fill:#FFFFFF;" cx="71.401" cy="95.517" r="1.846" />
                                    </g>
                                    <path style="fill:#684B37;"
                                        d="M64.217,83.925c2.919,1.117,7.605-3.798,18.335,0.478c1.956,0.78,3.123-6.668-8.378-6.668      C64.217,77.735,62.453,83.25,64.217,83.925z" />
                                    <g>
                                        <ellipse style="fill:#3B2519;" cx="130.406" cy="98.502" rx="6.173" ry="6.761" />
                                        <circle style="fill:#FFFFFF;" cx="127.715" cy="95.517" r="1.846" />
                                    </g>
                                    <path style="fill:#684B37;"
                                        d="M139.519,83.925c-2.919,1.117-7.606-3.798-18.335,0.478c-1.956,0.78-3.123-6.668,8.377-6.668      C139.519,77.735,141.282,83.25,139.519,83.925z" />
                                </g>
                                <path style="fill:#FCBC85;"
                                    d="M101.867,122.283c-6.099,0-9.773-4.557-9.773-2.352c0,2.204,1.764,6.394,9.773,6.394     c8.011,0,9.774-4.189,9.774-6.394C111.642,117.726,107.967,122.283,101.867,122.283z" />
                                <path style="fill:#FCBC85;"
                                    d="M101.867,142.553c-2.109,0-3.38-1.575-3.38-0.814c0,0.763,0.61,2.212,3.38,2.212     c2.771,0,3.381-1.449,3.381-2.212C105.248,140.978,103.978,142.553,101.867,142.553z" />
                                <path style="fill:#F7945E;"
                                    d="M101.867,136.936c-9.668,0-15.492-3.937-15.492-2.939s2.795,4.924,15.492,4.924     s15.493-3.927,15.493-4.924S111.535,136.936,101.867,136.936z" />
                            </g>
                            <circle style="fill:#F9AA8D;" cx="66.154" cy="120.108" r="8.377" />
                            <circle style="fill:#F9AA8D;" cx="138.611" cy="120.108" r="8.377" />
                        </g>
                        <path style="fill:#F5A860;"
                            d="M101.867,166.699v37.037H37.495C37.495,188.745,68.064,166.699,101.867,166.699z" />
                        <path style="fill:#F5A860;"
                            d="M101.867,166.699v37.037h64.373C166.24,188.745,135.672,166.699,101.867,166.699z" />
                        <path style="fill:#F18B45;"
                            d="M89.6,167.651c-0.189,0.027-0.379,0.065-0.569,0.094c-3.963,0.634-7.829,1.529-11.543,2.653   l24.38,33.338v-18.518L90.87,167.501C90.449,167.56,90.02,167.587,89.6,167.651z" />
                        <path style="fill:#F18B45;"
                            d="M114.135,167.651c0.19,0.027,0.38,0.065,0.57,0.094c3.963,0.634,7.829,1.529,11.543,2.653   l-24.381,33.338v-18.518l10.998-17.716C113.286,167.56,113.716,167.587,114.135,167.651z" />
                        <path style="fill:#684B37;"
                            d="M101.867,0C35.436,0,27.651,46.991,30.441,102.42c2.79,55.43,49.824,82.797,49.824,82.797   S27.56,109.79,71.401,43.651c0,0-8.232,14.157-7.786,26.015c0,0,10.328-3.527,38.253-3.527c27.926,0,38.253,3.527,38.253,3.527   c0.447-11.858-7.785-26.015-7.785-26.015c43.841,66.138-8.865,141.566-8.865,141.566s47.035-27.367,49.825-82.797   C176.085,46.991,168.3,0,101.867,0z" />
                        <path style="fill:#FDCC9B;"
                            d="M87.17,167.981l14.697,24.408l14.697-24.409C116.565,167.98,101.317,162.428,87.17,167.981z" />
                    </g>
                </svg>
            </div>
        </div>
        <!-- Rukns -->
        <h1
        class="my-8 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-dark">
        <span class="text-blue-600 dark:text-blue-500">Rukun</span>lardan birini tanlang</h1>

        <RuknsVue :gender="genderType"/> 

        <!-- by Alphabet -->
        <h1
        class="my-8 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-dark">
        <span class="text-blue-600 dark:text-blue-500">Alfabet</span> bo'yicha qidiring</h1>

        <Alphabet @letter_alpha="filterNamesListND"/> 
    </div>
</template>

<script>
import RuknsVue from '../Rukns.vue';
import Alphabet from '../Alphabet.vue';
import MiniNavbar from '../MiniNavbar.vue';
export default {
    name: 'GendersVue',
    components:{
        RuknsVue,
        Alphabet,
        MiniNavbar,
    },
    data() {
        return {
            genderType: '',
        };
    },

    created(){
        const {type} = this.$route.query;
        this.genderType = type;
    },
    
    methods: {
        filterNamesListND(filterBy){
            this.$router.push({name:'gender-names-list', query:{ gender:this.genderType, filterby:filterBy}});
        }
    },
};
</script>

<style lang="scss" scoped></style>