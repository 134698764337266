<template>
    <div class="text-left p-4 lg:max-w-7xl lg:mx-auto">
        <BackspaceButton />
        <br>
        <div class="p-4 rounded-2xl my-4 space-y-4" :class="search_name.gender == 'WOMEN' ? 'bg-red-100' : 'bg-blue-100'">
            <div class="flex justify-between items-center space-x-1">
                <h2 class="font-bold text-xl mb-4"><span
                        :class="search_name.gender == 'WOMEN' ? 'text-red-500' : 'text-blue-500'"
                        class="font-bold text-3xl">{{ search_name.latinName }}</span> ismining ma'nosi</h2>

                <div class="flex items-center">
                    <button v-if="isLiked"
                        class="inline-flex items-center justify-center p-2 text-pink-100 transition-colors duration-150 bg-transparent rounded-full focus:shadow-outline ">

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                            class="like-button" @click="clickLike" :fill="'red'" stroke="red" stroke-width="2">
                            <path
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                    </button>
                    <button v-else
                        class="inline-flex items-center justify-center p-2 text-pink-100 transition-colors duration-150 bg-transparent rounded-full focus:shadow-outline ">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                            class="like-button" @click="clickLike" :fill="'none'" stroke="red" stroke-width="2">
                            <path
                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                        </svg>
                    </button>
                    <span class="text-black text-xl">{{ search_name.likeCount }}</span>

                </div>
            </div>


            <div class="space-y-4 mb-4">
                <p></p>
                <p class="text-xl"> {{ search_name.latinDesc }}
                </p>
                <p class="flex pl-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                    </svg>
                    <span class="font-bold italic mr-2  hover:underline"> Ўз: </span>{{ search_name.krillDesc }}
                </p>
                <p class="flex pl-4"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                    </svg>
                    <span class="font-bold italic mr-2 hover:underline"> Eng: </span> {{ search_name.englishDesc }}
                </p>
                <!-- <p></p>

                <p>
                    <span class="font-bold">{{search_name.latinName}}</span> ismining ingliz tilidagi ma'nosini <a
                        href="https://uznames.com/?utm_source=ismlar" class="font-bold">Uznames.com</a> saytida tanishsangiz
                    bo'ladi.
                </p> -->
            </div>
            <div class="flex text-sm items-center space-x-3 justify-end">


                <div class="text-gray-500 flex justify-end items-center space-x-2">
                    <div class=" flex space-x-1 items-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="inline">
                            <path
                                d="M16.7539 7.27148C18.4102 8.92773 18.4102 11.584 16.7539 13.2402L13.2539 16.7715C12.9727 17.084 12.5039 17.084 12.1914 16.8027C11.9102 16.4902 11.9102 16.0215 12.1914 15.7402L15.6914 12.1777C16.7539 11.1152 16.7539 9.39648 15.6914 8.33398L11.6914 4.27148C11.4102 3.99023 11.4102 3.52148 11.7227 3.20898C12.0039 2.92773 12.4727 2.92773 12.7539 3.24023L16.7539 7.27148ZM2.00391 9.17773V4.49023C2.00391 3.67773 2.66016 2.99023 3.50391 2.99023H8.16016C8.69141 2.99023 9.19141 3.20898 9.56641 3.58398L14.8164 8.83398C15.5977 9.61523 15.5977 10.8965 14.8164 11.6777L10.6602 15.834C9.87891 16.6152 8.59766 16.6152 7.81641 15.834L2.56641 10.584C2.19141 10.209 2.00391 9.70898 2.00391 9.17773ZM5.50391 5.49023C4.94141 5.49023 4.50391 5.95898 4.50391 6.49023C4.50391 7.05273 4.94141 7.49023 5.50391 7.49023C6.03516 7.49023 6.50391 7.05273 6.50391 6.49023C6.50391 5.95898 6.03516 5.49023 5.50391 5.49023Z">
                            </path>
                        </svg>

                        <div class="capitalize">
                            <router-link :to="{ name: 'names-list', query: { rukn: search_name.rukn } }" class="">
                                <span v-for="rukn in beautyRukn" :key="rukn.id">{{ rukn }} </span>
                            </router-link>
                        </div>
                    </div>
                    <div class="whitespace-nowrap flex items-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="inline">
                            <path
                                d="M9.73438 7.03125C9.82812 7.03125 9.92188 7 10.0156 7C11.6719 7 13.0156 8.34375 13.0156 10C13.0156 11.6562 11.6719 13 10.0156 13C8.32812 13 7.01562 11.6562 7.01562 10C7.01562 9.9375 7.01562 9.84375 7.01562 9.75C7.29688 9.90625 7.64062 10 8.01562 10C9.10938 10 10.0156 9.125 10.0156 8C10.0156 7.65625 9.89062 7.3125 9.73438 7.03125ZM16.0156 5.53125C17.4844 6.875 18.4531 8.5 18.9219 9.625C19.0156 9.875 19.0156 10.1562 18.9219 10.4062C18.4531 11.5 17.4844 13.125 16.0156 14.5C14.5469 15.875 12.5156 17 10.0156 17C7.48438 17 5.45312 15.875 3.98438 14.5C2.51562 13.125 1.54688 11.5 1.07812 10.4062C0.984375 10.1562 0.984375 9.875 1.07812 9.625C1.54688 8.5 2.51562 6.875 3.98438 5.53125C5.45312 4.15625 7.48438 3 10.0156 3C12.5156 3 14.5469 4.15625 16.0156 5.53125ZM10.0156 5.5C7.51562 5.5 5.51562 7.53125 5.51562 10C5.51562 12.5 7.51562 14.5 10.0156 14.5C12.4844 14.5 14.5156 12.5 14.5156 10C14.5156 7.53125 12.4844 5.5 10.0156 5.5Z">
                            </path>
                        </svg>
                        <span title="Кўришлар сони">{{ search_name.seenCount }}</span>
                    </div>
                </div>
            </div>
            <router-link :to="{ name: 'offer', query: { name: (search_name.id) } }"
                class="font-bold py-2 px-4 bg-white rounded-full flex justify-center text-center"
                :class="search_name.gender == 'WOMEN' ? 'text-red-500' : 'text-blue-500'">
                Qo'shimcha ma'lumot yuborish </router-link>
        </div>
        <div class="p-4 rounded-2xl my-4 bg-yellow-100">
            <div>Ismning teskari yozilishi</div>
            <h5 class="font-bold text-xl">
                <span class="font-bold text-2xl"
                    :class="search_name.gender == 'WOMEN' ? 'text-red-500' : 'text-blue-500'">{{ reverseName }}</span>
            </h5>
        </div>
    </div>
</template>

<script>
// import Offers from './Offers.vue';
import axios from '@/axiosInstance'
import { eventBus } from '@/store/eventBus';
import BackspaceButton from '../BackspaceButton.vue';
export default {
    name: 'NameDetailVue',
    components: {
        // Offers,
        BackspaceButton,
    },
    data() {
        return {
            search_name: {},
            reverseName: '',
            beautyRukn: [],
            isLiked: false,
        };
    },

    created() {
        this.getNameDetail(this.$route.query.name);
        this.isLiked = this.isNameLiked(this.$route.query.name)
    },

    // computed: {
    //     isFilled() {
    //         return this.isNameLiked(this.$route.query.name);
    //     },
    // },

    methods: {
        isNameLiked(name) {
            const likedNamesList = JSON.parse(localStorage.getItem('likedNames')) || [];
            return likedNamesList.includes(name);
        },
        async getNameDetail(nameId) {
            //localStorage da biz ko'rilgan ismlarni id sini yig'ib boramiz

            const countedNamesList = JSON.parse(localStorage.getItem('seenCountedNames')) || [];

            if (!countedNamesList.includes(nameId)) {

                try {
                    const response = await axios.get(`/api/names/v1/get/${nameId}`);

                    this.search_name = response.data;
                    this.beautyRukn = eventBus.rukns[this.search_name.rukn];
                    this.reverseName = this.search_name.latinName.split("").reverse().join("");

                    countedNamesList.push(nameId);

                    localStorage.setItem('seenCountedNames', JSON.stringify(countedNamesList));

                } catch (error) {
                    console.log(error);
                }

            } else {
                try {
                    const response = await axios.get(`/api/names/v1`);
                    this.search_name = response.data.find(obj => obj.id === nameId);
                    this.beautyRukn = eventBus.rukns[this.search_name.rukn];
                    this.reverseName = this.search_name.latinName.split("").reverse().join("");
                } catch (error) {
                    console.log(error);
                }
            }
        },
        clickLike() {
            this.isLiked = !this.isLiked
            const likedNamesList = JSON.parse(localStorage.getItem('likedNames')) || [];
            const name = this.$route.query.name;

            if (!likedNamesList.includes(name)) {
                axios.post(`/api/names/v1/${name}/like`)
                    .then(() => {
                        likedNamesList.push(this.$route.query.name);
                        localStorage.setItem('likedNames', JSON.stringify(likedNamesList));

                        this.getNameDetail(name);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                axios.post(`/api/names/v1/${name}/like_undo`)
                    .then(() => {
                        const updatedNamesList = likedNamesList.filter(likedName => likedName !== name);
                        localStorage.setItem('likedNames', JSON.stringify(updatedNamesList))

                        this.getNameDetail(name);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }

        },
    },
};
</script>

<style scoped>
.like-button:hover {
    fill: red;
    transition: fill 0.1s;
}

/* .icon-path:active {
  fill: ;
} */
</style>