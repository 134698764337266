<template>
  <div>
    <!-- Help text -->
    <div class="flex justify-center">
      <span class="text-sm text-gray-700 dark:text-gray-400">
        
        <span class="font-semibold text-gray-900 dark:text-gray">{{ currentPageStart }}</span>
        dan
        <span class="font-semibold text-gray-900 dark:text-gray">{{ currentPageEnd }}</span>
        gacha ma'lumot. Umumiy 
        <span class="font-semibold text-gray-900 dark:text-gray">{{ list.length }}</span>
        ta 
      </span>
    </div>

    <!-- Component -->
    <div class="flex items-center justify-center py-4 lg:px-0 sm:px-6 px-4">
      <div class="flex space-x-2 items-center justify-between border-t border-gray-200">
        <div
          class="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer"
          @click="pageChanged(currentPage - 1)"
          :disabled="currentPage === 1"
        >
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.1665 4H12.8332"
              stroke="currentColor"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.1665 4L4.49984 7.33333"
              stroke="currentColor"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M1.1665 4.00002L4.49984 0.666687"
              stroke="currentColor"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p role="button" class="text-sm ml-3 font-medium leading-none">
            {{ previousLabel }}
          </p>
        </div>

        <div
          v-if="displayDotsBefore"
          class="flex items-center pt-3 text-gray-600 cursor-pointer"
          @click="pageChanged(pageCount - dotsCount + 1)"
        >
          <p class="text-sm p-2 bg-blue-500 font-medium leading-none">...</p>
        </div>

        <div class="flex items-center pt-3 text-gray-600">
          <p
            v-for="i in displayedPages"
            :key="i"
            @click="pageChanged(i)"
            class="cursor-pointer"
            :class="['text-sm', 'font-medium', 'leading-none', 'mr-3', { 'text-black bg-blue-400 p-2 rounded': i === currentPage }]"
            :disabled="i === currentPage"
            :style="i === currentPage ? activePageStyle : {}"
          >
            {{ i }}
          </p>
        </div>

        <div
          v-if="displayDotsAfter"
          class="flex items-center pt-3 text-gray-600 cursor-pointer"
          @click="pageChanged(dotsCount + 2)"
        >
          <p class="text-sm font-medium leading-none">...</p>
        </div>

        <div
          class="flex items-center pt-3 text-gray-600 hover:text-indigo-700 cursor-pointer"
          @click="pageChanged(currentPage + 1)"
          :disabled="currentPage === pageCount"
        >
          <p role="button" class="text-sm font-medium leading-none mr-3">
            {{ nextLabel }}
          </p>
          <svg
            width="14"
            height="8"
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.1665 4H12.8332"
              stroke="currentColor"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.5 7.33333L12.8333 4"
              stroke="currentColor"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.5 0.666687L12.8333 4.00002"
              stroke="currentColor"
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginationVue',
  props: {
    list: {
      type: Array,
      required: true,
    },
    number: {
      type: Number,
      default: 10,
    },
    previousLabel: {
      type: String,
      default: 'Orqaga',
    },
    nextLabel: {
      type: String,
      default: 'Oldingga',
    },
    dotsCount: {
      type: Number,
      default: 3,
    },
    activePageStyle: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  computed: {
    perPage() {
      return this.number;
    },
    pageCount() {
      return Math.ceil(this.list.length / this.perPage);
    },
    displayedPages() {
      const pages = [];
      const pageCount = this.pageCount;
      const currentPage = this.currentPage;
      const dotsCount = this.dotsCount;

      // Add first page
      pages.push(1);

      // Add pages around current page
      for (let i = currentPage - dotsCount; i <= currentPage + dotsCount; i++) {
        if (i > 1 && i < pageCount) {
          pages.push(i);
        }
      }

      // Add last page
      pages.push(pageCount);

      return pages;
    },
    displayDotsBefore() {
      return this.displayedPages[0] > 2;
    },
    displayDotsAfter() {
      const pageCount = this.pageCount;
      const lastDisplayedPage = this.displayedPages[this.displayedPages.length - 1];
      return lastDisplayedPage < pageCount - 1;
    },
    currentPageStart() {
      return (this.currentPage - 1) * this.perPage + 1;
    },
    currentPageEnd() {
      const end = this.currentPage * this.perPage;
      return end > this.list.length ? this.list.length : end;
    },
  },
  created() {
    this.$emit('paginated', this.getPaginatedData());
  },
  methods: {
    pageChanged(page) {
      if (page >= 1 && page <= this.pageCount) {
        this.currentPage = page;
        this.$emit('paginated', this.getPaginatedData());
      }
    },
    getPaginatedData() {
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      return this.list.slice(startIndex, endIndex);
    },
  },
};
</script>

<style scoped></style>
