<template>
    <nav class="bg-white text-gray-900">
        <div class="container mx-auto flex items-center justify-between p-4">
            <a href='/' class="flex items-center space-x-2 py-5 px-5 font-bold">
                <img src="./../assets/landing/bgimage2.jpg" alt="meaning-of-names" width="50" height="50">
                <span class="hidden lg:block md:block" style="font-size: 8px;width: 60px;">O'zbekiston Respublikasi Adliya Vazirligi</span>
                <img src="./../assets/landing/bgimage1.jpg" alt="meaning-of-names" width="55" height="55">
                <span class="hidden lg:block md:block" style="font-size: 8px;width: 65px;">Oliy Ta'lim, Fan va Innovatsiyalar Vazirligi</span>
                <img src="./../assets/landing/lOGO.png" alt="meaning-of-names" width="50" height="50">
                <span class="hidden lg:block md:block" style="font-size: 7px;width: 100px;">Alisher Navoiy nomidagi Toshkent Davlat O'zbek tili va Adabiyoti
                    Universiteti </span>
                <img src="./../assets/landing/alfraganus.jpg" alt="meaning-of-names" width="50" height="50">
                <span class="hidden lg:block md:block" style="font-size: 8px;width: 60px;">Alfraganus Universiteti</span>
                <!-- <span class="text-md text-gray-700" v-if="isMobileMenuOpen">ismlar-imlo</span> -->
            </a>
            
            <div class="lg:flex items-center space-x-2 md:hidden ">
                <router-link to="/names-list"
                            class="md:block hidden hover:bg-gray-200 px-4 py-2 text-lg rounded-lg">ISMLAR</router-link>
                        <router-link to="/article"
                            class="md:block hidden hover:bg-gray-200 px-4 py-2 text-lg rounded-lg">MAQOLALAR</router-link>
                        <router-link to="/about"
                            class="md:block hidden hover:bg-gray-200 px-4 py-2 text-lg rounded-lg">BIZ HAQIMIZDA</router-link>
                        
                            <span class="md:block hidden hover:bg-gray-200 text-lg rounded-lg">
                                <img style="cursor: pointer;" src="./../assets/landing/Uzbek.png" alt="meaning-of-names" width="30" height="25"> 
                            </span>
            </div>
            <button @click="toggleMobileMenu" class="lg:hidden text-white focus:outline-none">
                <svg class="h-6 w-6" fill="none" stroke="gray" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16">
                    </path>
                </svg>
            </button>
        </div>
        <div v-if="isMobileMenuOpen" class="lg:hidden">
            <ul>             
                <li><router-link to="/names-list"
                            class="block  hover:bg-gray-200 px-4 py-2 text-lg rounded-lg">ISMLAR</router-link></li>
                <li><router-link to="/article"
                            class="block  hover:bg-gray-200 px-4 py-2 text-lg rounded-lg">MAQOLALAR</router-link></li>
                <li><router-link to="/about"
                            class="block  hover:bg-gray-200 px-4 py-2 text-lg rounded-lg">BIZ HAQIMIZDA</router-link></li>
                <!-- Add more menu items as needed -->
            </ul>
        </div>
    </nav>
</template>
  
<script>
export default {
    data() {
        return {
            isMobileMenuOpen: false,
        };
    },
    methods: {
        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen;
        },
    },
};
</script>
  
<style scoped>/* Add your Tailwind classes or custom styles here */</style>
  