<template>
   <div>
      <!-- ====== Blog Section Start -->
      <section class="pt-4 lg:pb-20">
         <div class="container">
            <MiniNavbar @search="getResultOfNamesBySearchValue" :search_item="searchItem" />
            <div class="flex flex-wrap justify-center -mx-4 pt-[40px]">
               <div class="w-full px-4">
                  <div class="text-center mx-auto mb-[25px] lg:mb-10 max-w-[800px]">
                     <h2 class="
                  font-bold
                  text-3xl
                  sm:text-4xl
                  md:text-[40px]
                  text-dark
                  mb-4
                  ">
                        Ilmiy maqolalar sahifasi
                     </h2>
                     <p class="text-base text-body-color">
                        <!-- kichik matn uchun joy -->
                     </p>

                  </div>
               </div>
            </div>
            <div class="flex flex-wrap -mx-4">
               <!-- <div v-for="item in filterNames" :key="item.id" class="w-full md:w-1/2 lg:w-1/3 px-4">
                  <div class="max-w-[370px] mx-auto mb-10">
                     <div class="rounded overflow-hidden mb-8">
                        <img src="../../assets/landing/lOGO.png" alt="article-image" height="50"/>
                     </div>
                     <div>
                        <span class="
                     bg-primary
                     rounded
                     inline-block
                     text-center
                     py-1
                     px-4
                     text-xs
                     leading-loose
                     font-semibold
                     text-white
                     mb-5
                     ">
                           {{ getCreatedDate(item.createdDate) }}
                        </span>
                        <h3>
                           <router-link :to="{ name: 'article-detail', query: { id: item.id } }" class="
                        font-semibold
                        text-xl
                        sm:text-2xl
                        lg:text-xl
                        xl:text-2xl
                        mb-4
                        inline-block
                        text-dark
                        hover:text-primary
                        ">
                              {{ item.title }}
                           </router-link>
                        </h3>
                        <p class="text-base text-body-color">
                           {{ item.desc }}
                        </p>
                     </div>
                  </div>
                  <button @click="getDocumentByHashId(item.articleHashId)"
                     class="flex justify-center space-x-2 w-full bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded items-center">
                     <svg class="flex w-4 h-4 m-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                     </svg>
                     <span>Download PDF</span>
                  </button>

               </div> -->
               <!-- Container -->
               <div v-for="item in filterNames" :key="item.id" class="container mt-10 mx-auto p-4 md:p-0">

                  <!-- Card wrapper -->
                  <div v-if="!item.deleted" class="shadow-lg flex flex-wrap w-full lg:w-4/5 mx-auto">

                     <!-- Card image -->
                     <div class="bg-cover bg-bottom border w-full md:w-1/3 h-64 md:h-auto relative"
                        style="background-image:url(favicon.ico); background-size: contain; background-position: center; background-repeat: no-repeat;">
                        <div class="absolute text-xl">
                           <i class="fa fa-heart text-white hover:text-red-light ml-4 mt-4 cursor-pointer"></i>
                        </div>
                     </div>
                     <!-- ./Card image -->

                     <!-- Card body -->
                     <div class="bg-white w-full md:w-2/3">
                        <!-- Card body - outer wrapper -->
                        <div class="h-full mx-auto px-6 md:px-0 md:pt-6 md:-ml-6 relative">
                           <!-- Card body - inner wrapper -->
                           <div
                              class="bg-white lg:h-full p-6 -mt-6 md:mt-0 relative mb-4 md:mb-0 flex flex-wrap md:flex-wrap items-center">
                              <!-- Card title and subtitle -->
                              <div class="w-full lg:border-right lg:border-solid text-left">
                                 <h3 class="text-xl font-bold"> {{ item.title }} </h3>
                                 <p class="mb-0 mt-3 text-grey-dark text-sm"> {{ getCreatedDate(item.createdDate) }}
                                    {{ item.author }} </p>
                                 <hr class="w-1/4 md:ml-0 mt-4  border">
                              </div>
                              <!-- ./Card title and subtitle -->

                              <!-- Card description -->
                              <div class="w-full">
                                 <p class="text-md mt-4 mb-10 text-justify md:text-left text-sm italic">
                                    {{ item.desc }}
                                 </p>
                              </div>
                              <!-- ./Card description -->

                              <!-- Call to action button -->

                              <div class="w-full lg:w-1/2 mt-10 lg:mt-0 lg:px-4 text-center md:text-left">
                                 <button @click="goToDetail(item.id)"
                                    class="flex justify-center space-x-2 w-full bg-gray-300 hover:bg-blue-400 hover:text-white text-gray-800 font-bold py-2 px-4 rounded items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                       stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                       <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75" />
                                    </svg>


                                    <span>Batafsil</span>
                                 </button>
                              </div>
                              <div class="w-full lg:w-1/2 mt-6 lg:mt-0 lg:px-4 text-center md:text-left">
                                 <button @click="getDocumentByHashId(item.articleHashId)"
                                    class="flex justify-center space-x-2 w-full bg-gray-300 hover:bg-green-400 hover:text-white text-gray-800 font-bold py-2 px-4 rounded items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                       stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                       <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                    </svg>


                                    <span>Yuklab olish</span>
                                 </button>
                              </div>
                              <!-- ./Call to action button -->
                           </div>
                           <!-- ./Card body - inner wrapper -->
                        </div>
                        <!-- ./Card body - outer wrapper -->
                     </div>
                     <!-- ./Card body -->
                  </div>
                  <!-- ./Card wrapper -->
               </div>
               <!-- ./Container -->
            </div>
         </div>
      </section>
      <!-- ====== Blog Section End -->


   </div>
</template>

<script>
import MiniNavbar from '@/components/MiniNavbar.vue';
import axios from '@/axiosInstance';
export default {
   name: 'ArticleVue',
   components: {
      MiniNavbar
   },
   data() {
      return {
         articles: [],
         searchItem: '',
         filterNames: []
      };
   },
   computed: {
      getImageByHashId() {
         return (id) => {
            const imagePath = `/api/files/v1/get/${id}`
            return axios.defaults.baseURL + imagePath

         }
      },
      getCreatedDate() {
         return (time) => {
            const date = new Date(time);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear()).slice(2);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}`;
         }
      }
   },
   created() {
      axios.get('/api/articles/v1').then(response => {
         this.articles = response.data.reverse()
         this.filterNames = this.articles
      }).catch(error => console.log(error))
   },
   methods: {
      getResultOfNamesBySearchValue(value) {
         axios.get(`/api/articles/v1/search?query=${value}`).then(response => {
            this.filterNames = response.data.reverse();
         }).catch(error => console.log(error));
      },
      getDocumentByHashId(id) {
         axios.get(`/api/files/v1/get/${id}`, { responseType: 'arraybuffer' })
            .then(response => {
               const blob = new Blob([response.data], { type: 'application/pdf' });
               const url = window.URL.createObjectURL(blob);

               const hiddenElement = document.createElement('a');
               hiddenElement.href = url;
               hiddenElement.target = '_blank';
               hiddenElement.download = `${id}.pdf`;
               hiddenElement.click();

               window.URL.revokeObjectURL(url);
            })
            .catch(error => console.log(error));
      },

      goToDetail(id) {
         this.$router.push({ name: 'article-detail', query: { id: id } })
      }

   },

};
</script>

<style lang="scss" scoped></style>