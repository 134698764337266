<template>
    <div>
        <h1 class="text-xl font-bold mb-4">ISMNI TAHRIRLASH</h1>
        <!-- notification -->
        <div v-if="notify" id="toast-bottom-right" class="fixed flex items-center w-full max-w-xs left-2.5 bottom-5 "
            role="alert">
            <div class="text-sm font-normal">
                <div id="toast-danger"
                    class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow inset-0"
                    role="alert">
                    <div v-if="status == 'green'"
                        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span class="sr-only">Check icon</span>
                    </div>
                    <div v-if="status == 'red'"
                        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span class="sr-only">Error icon</span>
                    </div>
                    <div v-if="status == 'yellow'"
                        class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span class="sr-only">Warning icon</span>
                    </div>
                    <div class="ml-3 text-sm font-normal">{{ notify }}</div>
                    <button type="button" @click="closeNotif"
                        class="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-red-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8"
                        data-dismiss-target="#toast-success" aria-label="Close">
                        <span class="sr-only">Close</span>
                        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>
                </div>

            </div>
        </div>
        <!-- notification end -->
        <div class="text-left" v-if="data">
            <form>
                <div class="grid md:grid-cols-2 md:gap-6 py-4">
                    <div class="relative z-0 w-full mb-6 group">
                        <input type="text" v-model="data.latinName" name="floating_first_name" id="floating_first_name"
                            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none text-blue-700 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " required />
                        <label for="floating_first_name"
                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Lotin tilida ism</label>
                    </div>
                    <div class="relative z-0 w-full mb-6 group">
                        <input type="text" v-model="data.latinDesc" name="floating_last_name" id="floating_last_name"
                            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none text-blue-700 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " required />
                        <label for="floating_last_name"
                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Lotin tilida ismning ma'nosi</label>
                    </div>
                    <div class="relative z-0 w-full mb-6 group">
                        <input type="text" v-model="data.krillName" name="floating_last_name" id="floating_last_name"
                            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none text-blue-700 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " required />
                        <label for="floating_last_name"
                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Kриль имя </label>
                    </div>
                    <div class="relative z-0 w-full mb-6 group">
                        <input type="text" v-model="data.krillDesc" name="floating_last_name" id="floating_last_name"
                            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none text-blue-700 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " required />
                        <label for="floating_last_name"
                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Имя описание в криле</label>
                    </div>
                    <div class="relative z-0 w-full mb-6 group">
                        <input type="text" v-model="data.englishName" name="floating_last_name" id="floating_last_name"
                            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none text-blue-700 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " required />
                        <label for="floating_last_name"
                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">English
                            name</label>
                    </div>
                    <div class="relative z-0 w-full mb-6 group">
                        <input type="text" v-model="data.englishDesc" name="floating_last_name" id="floating_last_name"
                            class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none text-blue-700 dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                            placeholder=" " required />
                        <label for="floating_last_name"
                            class="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">English
                            Description of name</label>
                    </div>

                    <div class="relative z-0 w-full mb-6 group">
                        <h1 class="font-bold text-xl mb-4">
                            <span>
                                Birini tanlang:
                            </span>
                        </h1>
                        <div class="flex space-x-8">
                            <div class="flex items-center px-4 border border-gray-200 hover:border-gray-700 bg-white">
                                <input v-model="data.gender" id="bordered-radio-1" type="radio" value="MEN"
                                    name="bordered-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 ">
                                <label for="bordered-radio-1"
                                    class="flex items-center w-full px-4 py-2 ml-2 text-md font-medium text-gray-900">
                                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 11C9.23858 11 7 13.2386 7 16C7 18.7614 9.23858 21 12 21C14.7614 21 17 18.7614 17 16C17 13.2386 14.7614 11 12 11ZM12 11V3M12 3L16 7M12 3L8 7"
                                            stroke="#001A72" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <span>O'g'il bola</span>
                                </label>
                            </div>
                            <div class="flex items-center px-4 border border-gray-200 hover:border-gray-700 bg-white">
                                <input v-model="data.gender" checked id="bordered-radio-2" type="radio" value="WOMEN"
                                    name="bordered-radio"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 ">
                                <label for="bordered-radio-2"
                                    class="flex items-center w-full py-2 px-4 ml-2 text-md font-medium text-gray-900">
                                    <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M12 13C14.7614 13 17 10.7614 17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8C7 10.7614 9.23858 13 12 13ZM12 13L12 21M9 18L15 18"
                                            stroke="#001A72" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                    </svg>
                                    <span>
                                        Qiz bola
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="relative z-0 w-full mb-6 group">
                        <label for="underline_select" class="sr-only">Select Rukns</label>
                        <MultiSelect :updateRukn="data.rukn" @change="handleSelectedRukn" />
                    </div>

                    <div class="relative z-0 w-full mb-6 group">
                        <div class="flex items-center">
                            <input id="link-checkbox" type="checkbox" value="true" v-model="data.deleted"
                                class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                            <label for="link-checkbox" class="ml-2 text-md font-medium text-gray-900">I agree the name is
                                <span class="bg-yellow-400 text-white font-bold px-2">DELETED</span> </label>
                        </div>
                    </div>
                </div>
                <div class="space-x-2">
                    <button type="submit" @click="editName"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Saqlash</button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import axios from '@/axiosInstance';
import MultiSelect from '@/components/MultiSelect.vue'
import { eventBus } from '@/store/eventBus';

export default {
    name: 'UpdateName',
    components: {
        MultiSelect,
    },

    data() {
        return {
            data: {},
            isDataValid: false,
            notify: '',
            status: '',
        };
    },

    created() {
        this.data = this.$route.query.name;
    },

    methods: {
        handleSelectedRukn(rukn_list) {
            let selectedRukns = JSON.stringify(rukn_list.slice())
            let reversedRukns = JSON.stringify(rukn_list.slice().reverse())

            this.data.rukn = Object.keys(eventBus.rukns).find(key =>
                (JSON.stringify(eventBus.rukns[key]) == selectedRukns) || (JSON.stringify(eventBus.rukns[key]) == reversedRukns)
            )
            if (this.data.rukn) {
                this.notificationWarning(`${this.data.rukn} rukn mavjud.`, 'green')
            } else {
                this.notificationWarning(`${this.data.rukn}. Rukn topilmadi!`, 'red')
            }
        },
        async editName(event) {
            // console.log(this.data)
            event.preventDefault();
            if (this.data.rukn) {
                this.isDataValid = this.data.latinName.trim() !== '' &&
                    this.data.latinDesc.trim() !== '' &&
                    this.data.krillName.trim() !== '' &&
                    this.data.krillDesc.trim() !== '' &&
                    this.data.englishName.trim() !== '' &&
                    this.data.englishDesc.trim() !== '' &&
                    this.data.gender.trim() !== '';
            }
            if (this.isDataValid) {

                axios.put(`/api/names/v1/${this.$route.query.name.id}`, this.data).then(response => {
                    console.log(response)
                    this.notificationWarning(`O'zgarishlash saqlandi! Natija ${response.status}`, 'green')
                    setTimeout(() => {
                        this.notify = ''
                        this.$router.push({ name: 'names' })
                    }, 1000);
                }).catch(error => {
                    console.log(error)
                    this.notificationWarning('Kutilmaga xatolik bor!', 'red')
                });

            } else {
                this.notificationWarning('Ma\'lumotlar to\'liq emas!', 'red')
            }
        },
        notificationWarning(text = '', status = '') {
            this.status = status
            this.notify = text
            setTimeout(() => {
                this.notify = ''
            }, 2000);
        },
        closeNotif() {
            this.notify = ''
        }
    },
};
</script>

<style scoped></style>