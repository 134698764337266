<template>
    <div>
        <div class="w-full flex flex-wrap justify-center text-black">
            <div v-for="(letter, index) in lettersAlpha" :key="index" :class="{'bg-blue-700':index == selectButton}" class="text-sm md:text-xl lg:text-xl text-center px-4 py-2 hover:bg-gray-200 rounded-xl font-bold" @click="filterNames(letter, index)" role="button">{{ letter.toUpperCase() }}</div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'AlphabetVue',
    
    data() {
        return {
            lettersAlpha : ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'],
            selectButton : null,
        };
    },

    methods: {
        filterNames(letter, index) {
            this.selectButton = index;
            this.$emit('letter_alpha', letter)
        }
    },
};
</script>

<style scoped></style>