<template>
    <div class="p-4 lg:max-w-7xl lg:mx-auto pb-36">
        <BackspaceButton />
        <h1
            class="mt-8 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-dark">
            Eng <span class="text-blue-600 dark:text-blue-500">Ko'p Qidirilgan </span> ismlar
        </h1>
        <div class="items-center justify-center pt-10">
            <div class="pb-4">
                <p class="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400 mx-4">Ushbu sahifada saytimiz
                    bo‘yicha eng ko‘p qidirilgan 100 ta ismlar ro‘yxati berilgan</p>
            </div>
        </div>
        <div v-if="topGirlsName.length < 6" class="p-4 rounded-2xl bg-cyan-100 my-4 mx-8">
            <h5 class="font-bold mb-2">🚹O'G'IL BOLALAR</h5>
            <ul class="list-none text-cyan-500 text-left">
                <li v-for=" (boy, index) in topBoysName" :key="boy.id"
                    class="hover:bg-white px-4 rounded-xl mb-1 flex items-center">
                    <div class="text-gray-400 w-7 mr-2">{{ index + 1 }}</div>
                    <router-link :to="{ name: 'detail', query: { name: boy.id } }"
                        class="py-2 grow text-left hover:underline">
                        {{ boy.latinName }}
                    </router-link>
                    <div class="p-2 cursor-pointer flex items-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                            <path
                                d="M9.625 4.28499L9.96875 4.65999L10.3438 4.31624C11.375 3.28499 12.8438 2.81624 14.25 3.06624C16.4062 3.40999 18 5.28499 18 7.47249V7.62874C18 8.94124 17.4375 10.1912 16.5 11.0662L10.8438 16.3475C10.625 16.5662 10.3125 16.66 10 16.66C9.65625 16.66 9.34375 16.5662 9.125 16.3475L3.46875 11.0662C2.53125 10.1912 2 8.94124 2 7.62874V7.47249C2 5.28499 3.5625 3.40999 5.71875 3.06624C7.125 2.81624 8.59375 3.28499 9.625 4.28499C9.625 4.31624 9.59375 4.28499 9.625 4.28499ZM9.96875 6.78499L8.5625 5.34749C7.875 4.69124 6.90625 4.37874 5.96875 4.53499C4.53125 4.78499 3.5 6.00374 3.5 7.47249V7.62874C3.5 8.53499 3.84375 9.37874 4.5 9.97249L10 15.0975L15.4688 9.97249C16.125 9.37874 16.5 8.53499 16.5 7.62874V7.47249C16.5 6.00374 15.4375 4.78499 14 4.53499C13.0625 4.37874 12.0938 4.69124 11.4062 5.34749L9.96875 6.78499Z">
                            </path>
                        </svg>
                        <span>{{ boy.likeCount }}</span>
                    </div>
                    <div class="p-2 cursor-pointer flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" width="20" height="20" class="mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span>{{ boy.seenCount }}</span>
                    </div>
                    <div class="p-2 cursor-pointer flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" width="20" height="20">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                        </svg>

                        <span>{{ getBeautyRukn(boy.rukn) }}</span>
                    </div>
                </li>


            </ul>
            <div role="button" @click="moreTopNames('boys')" v-if="topBoysName.length < 6"
                class="morenamebtn block py-2 px-4 rounded-full bg-white text-center">Barchasini ko'rish</div>
        </div>

        <div v-if="topBoysName.length < 6" class="p-4 rounded-2xl bg-purple-100 my-4 mx-8">
            <h5 class="font-bold mb-2">🚺QIZ BOLALAR</h5>
            <ul class="list-none text-purple-500 text-left">
                <li v-for=" (girl, index) in topGirlsName" :key="girl.id"
                    class="hover:bg-white px-4 rounded-xl mb-1 flex items-center">
                    <div class="text-gray-400 w-7 mr-2">{{ index + 1 }}</div>
                    <router-link :to="{ name: 'detail', query: { name: girl.id } }" class="py-2 grow text-left">
                        {{ girl.latinName }}
                    </router-link>
                    <div class="p-2 cursor-pointer flex items-center">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="purple">
                            <path
                                d="M9.625 4.28499L9.96875 4.65999L10.3438 4.31624C11.375 3.28499 12.8438 2.81624 14.25 3.06624C16.4062 3.40999 18 5.28499 18 7.47249V7.62874C18 8.94124 17.4375 10.1912 16.5 11.0662L10.8438 16.3475C10.625 16.5662 10.3125 16.66 10 16.66C9.65625 16.66 9.34375 16.5662 9.125 16.3475L3.46875 11.0662C2.53125 10.1912 2 8.94124 2 7.62874V7.47249C2 5.28499 3.5625 3.40999 5.71875 3.06624C7.125 2.81624 8.59375 3.28499 9.625 4.28499C9.625 4.31624 9.59375 4.28499 9.625 4.28499ZM9.96875 6.78499L8.5625 5.34749C7.875 4.69124 6.90625 4.37874 5.96875 4.53499C4.53125 4.78499 3.5 6.00374 3.5 7.47249V7.62874C3.5 8.53499 3.84375 9.37874 4.5 9.97249L10 15.0975L15.4688 9.97249C16.125 9.37874 16.5 8.53499 16.5 7.62874V7.47249C16.5 6.00374 15.4375 4.78499 14 4.53499C13.0625 4.37874 12.0938 4.69124 11.4062 5.34749L9.96875 6.78499Z">
                            </path>
                        </svg>
                        <span>{{ girl.likeCount }}</span>
                    </div>
                    <div class="p-2 cursor-pointer flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="purple" width="20" height="20">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>

                        <span>{{ girl.seenCount }}</span>
                    </div>
                    <div class="p-2 cursor-pointer flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="purple" width="20" height="20">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                        </svg>

                        <span>{{ getBeautyRukn(girl.rukn) }}</span>
                    </div>
                </li>
            </ul>
            <div role="button" @click="moreTopNames('girls')" v-if="topGirlsName.length < 6"
                class="morenamebtn block py-2 px-4 rounded-full bg-white text-center"><span>Barchasini ko'rish</span></div>
        </div>

    </div>
</template>

<script>
import BackspaceButton from '../BackspaceButton.vue';
import axios from '@/axiosInstance';
import { eventBus } from '@/store/eventBus';

export default {
    name: 'MostSearched',
    components: {
        BackspaceButton,
    },
    data() {
        return {
            topBoysName: [],
            topGirlsName: [],
            beautyRukn: '',
        };
    },
    async created() {
        const boysNames = await axios.get('/api/names/v1/boys');
        this.topBoysName = this.getSortedMostWantedNames(boysNames.data);
        // boysNames.data.sort((a, b) => b.seenCount - a.seenCount);
        const girlsNames = await axios.get('/api/names/v1/girls');
        this.topGirlsName = this.getSortedMostWantedNames(girlsNames.data);
        // girlsNames.data.sort((a, b) => b.seenCount - a.seenCount).slice(0, 5);
    },

    computed:{
        getBeautyRukn() {
            return (rukn_name)=>{
                this.beautyRukn = eventBus.rukns[rukn_name].join(', ')
                return this.beautyRukn;

            }
        },
        getSortedMostWantedNames(){
            return(data)=>{
                return data.sort((a, b) => b.seenCount - a.seenCount)
            }
        }
    },

    methods: {
        async moreTopNames(type) {
            try {
                const response = await axios.get(`/api/names/v1/${type}`);
                let names;
                if (type === 'boys') {
                    names = response.data;
                    this.topBoysName = names.sort((a, b) => b.seenCount - a.seenCount).slice(0, 100);
                } else {
                    names = response.data;
                    this.topGirlsName = names.sort((a, b) => b.seenCount - a.seenCount).slice(0, 100);
                }
            } catch (error) {
                console.log(error);
            }

        },
        

    },
};
</script>

<style scoped></style>