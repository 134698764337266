<template>
    <div>
        <div class="grid grid-cols-12 md:grid-cols-26 gap-2">
            <div v-for="letter in letters" :key="letter.id" @click="filterAllTo(letter)"
                class="bg-gray-300 hover:bg-gray-400 text-black font-bold rounded-md md:py-3 md:px-6 md:text-lg"
                role="button">{{ letter.toUpperCase() }}</div>
            

        </div>

    </div>
</template>

<script>
export default {
    name: 'LettersVue',

    data() {
        return {
            letters: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
        };
    },

    mounted() {

    },

    methods: {
        filterAllTo(letter) {
            this.$emit('letter', letter)
        }
    },
};
</script>

<style scoped></style>