<template>
  <div style=" background: url('https://www.tsuull.uz/sites/all/themes/sap/img/dist/bg.png')">
    <NavbarsVue />
    <section class="bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
      <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">

        <h1 class="mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl text-white">
          Ismlar ma'nosini bilasizmi?</h1>
        <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-200">
          Bizning ma'lumotlar bazamizda juda ko'p ismlar mavjud va siz ma'nolarini osongina topishingiz mumkin. Ammo
          ba'zida siz o'z ismingizni yoki hatto biron bir aniq ismni bilmasligingiz mumkin, lekin buning o'rniga yoqimli
          ism topish uchun g'oya olishni xohlaysiz. Buning uchun bizda yechim bor!</p>
        <form class="w-full max-w-md mx-auto" @submit="sendSearchData">

          <label for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Qidirish</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor"
                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
              </svg>
            </div>
            <input type="search" id="default-search" v-model="search_item"
              class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Ismingizning ma'nosini qidiring..." required>
            <button type="submit"
              class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Qidirish</button>
          </div>
        </form>
      </div>

    </section>

    <!-- KEYBORD -->
    <div class="py-4 wrapper max-w-screen-lg mx-auto">
      <h1
        class="mt-8 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-dark">
        <span class="text-blue-600 dark:text-blue-500">Rukn</span>lardan birini tanlang
      </h1>

      <div class="items-center justify-center pt-10">
        <Rukns />
      </div>

      <div class="flex items-center justify-center space-x-4 pt-10 px-4">
        <!-- First element (O'g'il bola) -->
        <div
          class="w-full max-w-sm w-64 bg-white rounded-lg hover:shadow-2xl  bg-cover bg-center bg-no-repeat bg-[length:1280px_250px] bg-[url('./../../assets/Boybcg.png')]"
          role="button" @click="goToGender('MEN')">
          <img class="svg-icon inline-flex" width="200" height="200" src="./../../assets/icons/boy.svg"
            alt="O'g'il bola icon">
          <h5 class="mb-1 text-xl font-bold dark:text-black pt-2">O'g'il bola</h5>
        </div>

        <!-- Second element (Qiz bola) -->
        <div
          class=" w-full max-w-sm w-64 bg-white rounded-lg hover:shadow-2xl  bg-cover bg-center bg-no-repeat bg-[length:1280px_250px] bg-[url('./../../assets/Girlbcg.png')]"
          role="button" @click="goToGender('WOMEN')">
          <img class="svg-icon inline-flex" width="200" height="200" src="./../../assets/icons/girl.svg"
            alt="Qiz bola icon">
          <h5 class="mb-1 pt-2 text-xl font-bold dark:text-black">Qiz bola</h5>
        </div>
      </div>



      <h1
        class="mt-8 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-dark">
        Ismingizni <span class="text-blue-600 dark:text-blue-500">Bosh Harfi</span> nima?</h1>

      <div class="items-center justify-center pt-10">
        <div class="pb-4">
          <p class="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400 mx-4">Bizda barcha ismlar va ma'nolar
            mavjud. Quyida siz alifboning barcha harflarini topasiz, aniqrog'i 26 ta. Shunchaki harfni tanlang va biz
            ushbu harf bilan boshlangan barcha nomlarni qidiramiz.</p>
        </div>
        <LettersVue @letter="goToAllNamesByLetters" />
      </div>

      <!-- Most searched -->
      <!-- bu joy da hammasi tayyor aslida. Commentdan chiqarilsa bsa -->
      <h1
        class="my-8 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-dark">
        Eng <span class="text-blue-600 dark:text-blue-500">Ko'p Qidirilgan</span> ismlar</h1>
      <MostWantedVue />




    </div>


  </div>
</template>

<script>
import NavbarsVue from '../NavbarResp.vue';
import LettersVue from '../Letters.vue';
import Rukns from '../Rukns.vue';
import MostWantedVue from '../MostWanted.vue';
// import axios from 'axios';
// import { mapMutations } from 'vuex';

export default {
  name: 'HomeVue',
  components: {
    NavbarsVue,
    Rukns,
    MostWantedVue,
    LettersVue
  },

  data() {
    return {
      search_item: '',
    };
  },

  mounted() {

  },

  methods: {
    goToGender(genderType) {
      this.$router.push(`/gender?type=${genderType}`);
    },
    sendSearchData(event) {
      event.preventDefault();
      this.$router.push({ name: 'names-list', query: { searchValue: this.search_item } })

    },
    goToAllNamesByLetters(letter) {
      this.$router.push({ name: 'gender-names-list', query: { filterby: letter } });
    }
  },
};
</script>

<style scoped></style>