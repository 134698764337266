<template>
    <div style="background: url('https://www.tsuull.uz/sites/all/themes/sap/img/dist/bg.png');">

        <section class="items-center py-5 px-8 bg-stone-100 xl:h-screen font-poppins">
            <BackspaceButton class="px-4" />
            <div class="flex justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">
                <div class="flex flex-wrap py-[10vh]">
                    <div class="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
                        <div class="relative">
                            <!-- <img src="https://i.postimg.cc/QtyYkbxp/pexels-andrea-piacquadio-927022.jpg" alt=""
                            class="relative z-40 object-cover w-full h-96 lg:rounded-tr-[80px] lg:rounded-bl-[80px] rounded">
                        <div
                            class="absolute z-10 hidden w-full h-full bg-blue-400 rounded-bl-[80px] rounded -bottom-6 right-6 lg:block">
                        </div>
                        <div
                            class="absolute z-50 text-blue-400 transform -translate-y-1/2 cursor-pointer top-1/2 left-[46%] hover:text-blue-500">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                class="w-14 h-14 bi bi-play-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z">
                                </path>
                            </svg>
                            
                        </div> -->
                            <div class="container">
                                <iframe class="responsive-iframe" src="https://www.youtube.com/embed/WzPef_-4_wk"></iframe>
                            </div>
                        </div>
                    </div>
                    <div class="w-full px-4 mb-10 lg:w-1/2 lg:mb-0 ">
                        <div class="relative">
                            <h1
                                class="absolute -top-20  left-0 text-[11px] lg:text-[50px] text-gray-900 font-bold  opacity-5 md:block hidden">
                                Ismlar - Imlo
                            </h1>
                            <h1 class="pl-2 text-3xl font-bold border-l-8 border-blue-400 md:text-5xl">
                                Loyihaning maqsadi
                            </h1>
                        </div>
                        <p class="mt-6 mb-10 text-justify leading-7 text-gray-500">
                            <b>Loyiha raqami:</b> IL-21091506 <br>
                            <b>Loyiha nomi:</b> O‘zbek ismlarining izohli imlo lug‘ati va mobil ilovasini yaratish.


                            Mazkur loyiha natijalari o‘zbek ismlari lug‘atini qadriyatlarimizga mos ismlar bilan boyitish va
                            zaxirasini tartibga solishga yordam beradi; Davlat xizmatlari agentligi tizimi, FHDY bo‘limlari,
                            fuqarolik pasportini qayd qilish sohalari mutaxassislarining ismlarni til qoidalari va
                            qonuniyatlariga mos tarzda qilishlari uchun muhim qo‘llanma bo‘lib xizmat qiladi; lug‘atning
                            zamonaviy elektron shakllarini yaratish kelgusida uni muntazam boyitib borish imkonini beradi va
                            ulardan istalgan paytda foydalanish uchun qulayliklar yaratadi.
                        </p>
                        <div role="button" @click="downloadPDF"
                            class="flex justify-center items-center w-[250px] px-4 py-3 text-gray-50 transition-all transform bg-blue-400 rounded-[80px] hover:bg-blue-500">
                            <span class="mr-2">Batafsil</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5" />
                            </svg>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 bg-blue-20">
            <!-- <div class="flex flex-col lg:flex-row justify-between gap-8">
            
                <div class="w-full lg:w-2/12 flex flex-col justify-center">
                    <img class="w-full h-full" src="./../../assets/landing/bgimage2.jpg" alt="A group of People" />

                </div>
                <div class="w-full lg:w-2/12 flex flex-col justify-center">
                    <img class="w-full h-full" src="./../../assets/landing/bgimage1.jpg" />

                </div>
                
                <div class="w-full lg:w-6/12">
                    <img class="w-full h-full" src="https://tiladabiyot-tm.uz/wp-content/uploads/2021/09/brandlogo.png" alt="A group of People" />
                </div>
            </div> -->

            <div class="mx-auto flex flex-col justify-between gap-8 pt-12">
                <div class="w-full flex flex-col justify-center">
                    <h1 class="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4">Loyihaning asosiy ijrochilari
                    </h1>
                    <!-- <p class="font-normal text-base leading-6 text-gray-600">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum.In the first place we have granted to God, and by this our present charter confirmed for us and our heirs forever that the English Church shall be free, and shall have her rights entire, and her liberties inviolate; and we will that it be thus observed; which is apparent from</p> -->
                </div>
                <div class="w-full bg-white lg:pt-8">
                    <div class="grid md:grid-cols-4 sm:grid-cols-2 grid-cols-1 lg:gap-4 shadow-lg rounded-md">
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/lOGO.png" width="200" height="200" alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Saparniyazova Muyassar Yo‘ldoshevna
                            </p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">LOYIHA RAHBARI</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/lOGO.png" width="200" height="200" alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Muhamedova Saodat Xudoyberdiyevna
                            </p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">ToshDO‘TAU</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/UzXIA.jpg" width="180" height="180" alt="Alexa featured Image" />
                           <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Lutfullayeva Durdona Esonovna </p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">O‘zXIA</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/UzXIA.jpg" width="180" height="180" alt="Liam featued Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Islomov Zohidjon Mahmudovich </p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">O‘zXIA</p>
                        </div>


                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/bgimage2.jpg" width="200" height="200"
                                alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Shahnoza Soatova</p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">O‘zR Adliya vazirligi</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/UzFATAFI.jpg" width="180" height="180"
                                alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Sobirova Muxlisa Kadamovna</p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">O‘zFA TAFI</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/UzFATAFI.jpg" width="180" height="180"
                                alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Manukyan Feruza Musaxanovna</p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">O‘zFA TAFI</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/UzFATAFI.jpg" width="180" height="180"
                                alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Axmedova Aziza Rustamovna</p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">O‘zFA TAFI</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/bgimage2.jpg" width="200" height="200"
                                alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Latipov Isomiddin Eshboyevich</p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">O‘zR Adliya vazirligi</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/lOGO.png" width="200" height="200" alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Shodmonova Munira Burxonovna </p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">ToshDO‘TAU</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/lOGO.png" width="200" height="200" alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Qodirova Xurshida Botirovna </p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">ToshDO‘TAU</p>
                        </div>
                        <div class="p-4 pb-6 flex justify-center flex-col items-center">
                            <img class="md:block hidden" src="../../assets/landing/lOGO.png" width="200" height="200" alt="Alexa featured Image" />
                            <p class="font-medium text-xl leading-5 text-gray-800 mt-4">Musulmonova Nargiza Rahmatullayevna
                            </p>
                            <p class="font-bold text-xl leading-5 text-gray-800 mt-4">ToshDO‘TAU</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <section class="flex items-center  xl:h-screen font-poppins dark:bg-gray-800 ">
            <div class="justify-center flex-1 max-w-6xl py-4 mx-auto lg:py-6 md:px-6">

                <div class="flex flex-wrap ">
                    <!-- <div class="w-full px-4 mb-10 lg:w-1/2 lg:mb-0">
                    <img src="https://i.postimg.cc/j5L5bX2d/pexels-andrea-piacquadio-3757946.jpg" alt=""
                        class="relative z-40 object-cover w-full h-96">
                </div> -->
                    <div class="w-full px-4 mb-10 lg:mb-0 ">
                        <h2
                            class="py-3 pl-2 mb-4 text-2xl font-bold text-gray-700 border-l-4 border-blue-500 dark:border-blue-400 dark:text-gray-300">
                            Ilmiy loyihani amalga oshirish natijasida nimalarga erishiladi?
                        </h2>
                        <p class="mb-4 text-justify leading-7 text-gray-500 dark:text-gray-400">
                            Davlat xizmatlari agentligi tizimida, xususan, FHDYo bo‘limlarida, shuningdek, fuqarolik
                            pasportlarini rasmiylashtirish sohalari uchun o‘zbek ismlarining lotin hamda kirill alifbosidagi
                            imlosi, ismlar ma'nosi izohi, ingliz tilidagi varianti aks etgan “O‘zbek ismlarining izohli imlo
                            lug‘ati” va uning mobil ilovasi hamda Veb-sayti yaratiladi.
                        </p>
                        <ul class="mb-10">
                            <li class="flex items-center mb-4 text-base text-gray-600 dark:text-gray-400">
                                <!-- <span class="mr-3 text-blue-500 dark:text-blue-400 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="w-5 h-5 bi bi-patch-check-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                </svg>
                            </span> -->
                                “O‘zbek ismlarining izohli imlo lug‘ati” uch qismdan iborat bo‘ladi:
                            </li>
                            <li class="flex items-center mb-4 text-left text-gray-600 dark:text-gray-400">
                                <span class="mr-3 text-blue-500 dark:text-blue-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-5 h-5 bi bi-patch-check-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                    </svg>
                                </span>
                                − kirish qismida ismlar, o‘zbek ismlari tarixi, ma'nosi, izohi haqida ma'lumotlar
                                keltiriladi; diniy qadriyatlarni aks ettirgan ismlar, ularning izohi, bugungi kunda bu
                                boradagi kamchiliklar xususida soha mutaxassislarining fikrlari keltiriladi;
                            </li>
                            <li class="flex items-center mb-4 text-left text-gray-600 dark:text-gray-400">
                                <span class="mr-3 text-blue-500 dark:text-blue-400 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-5 h-5 bi bi-patch-check-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                    </svg>
                                </span>
                                − “Lug‘at” - ushbu qismda o‘zbek ismlarining lotin hamda kirill alifbosidagi imlosi, ingliz
                                tilidagi varianti hamda ismlar ma'nosi izohi beriladi;
                            </li>
                            <li class="flex items-center mb-4 text-left text-gray-600 dark:text-gray-400">
                                <span class="mr-3 text-blue-500 dark:text-blue-400 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-5 h-5 bi bi-patch-check-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                    </svg>
                                </span>
                                − “Uslubiy ko‘rsatmalar” bo‘limi – soha mutaxassislarining imlo ko‘nikmalarini
                                rivojlantirish maqsadida maxsus uslubiy qo‘llanma keltiriladi;
                            </li>
                            <li class="flex items-center mb-4 text-left text-gray-600 dark:text-gray-400">
                                <span class="mr-3 text-blue-500 dark:text-blue-400 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-5 h-5 bi bi-patch-check-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                    </svg>
                                </span>
                                − ma'lumotlarni ilova etuvchi dasturiy ta'minot yaratiladi;

                            </li>
                            <li class="flex items-center mb-4 text-left text-gray-600 dark:text-gray-400">
                                <span class="mr-3 text-blue-500 dark:text-blue-400 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-5 h-5 bi bi-patch-check-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                    </svg>
                                </span>
                                − Internet tarmog‘ida on-line rejimda ishlaydigan mahsulot − o‘zbek ismlarining izohli imlo
                                lug‘ati yaratiladi;
                            </li>
                            <li class="flex items-center mb-4 text-left text-gray-600 dark:text-gray-400">
                                <span class="mr-3 text-blue-500 dark:text-blue-400 ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                        class="w-5 h-5 bi bi-patch-check-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
                                    </svg>
                                </span>
                                − tadqiqot natijalari OAK ro‘yxatidagi nufuzli ilmiy jurnallarda va Web of Science hamda
                                Scopus ma'lumotlar bazasida indeksatsiyalangan jurnallarda ilmiy maqolalar chop etiladi.
                            </li>
                        </ul>
                        <a href="#"
                            class="px-4 py-3 text-blue-700 transition-all transform border border-blue-500 hover:bg-blue-600 dark:border-blue-400 dark:hover:bg-blue-500 dark:hover:text-gray-100 dark:hover:border-blue-500 dark:text-blue-400 hover:text-gray-100">
                            Yuqoriga
                        </a>
                    </div>
                </div>
            </div>
        </section>


    </div>
</template>

<script>
import BackspaceButton from '@/components/BackspaceButton.vue';
import axios from '@/axiosInstance';
export default {
    name: 'AboutUs',
    components: {
        BackspaceButton,
    },
    data() {
        return {

        };
    },

    methods: {
        downloadPDF() {
            
            axios.get(`/api/files/v1/get/39967c28-3347-43c6-a6cb-d97a9850098f`, { responseType: 'arraybuffer' })
            .then(response => {
               const blob = new Blob([response.data], { type: 'application/pdf' });
               const url = window.URL.createObjectURL(blob);

               const hiddenElement = document.createElement('a');
               hiddenElement.href = url;
               hiddenElement.target = '_blank';
               hiddenElement.download = `39967c28-3347-43c6-a6cb-d97a9850098f.pdf`;
               hiddenElement.click();

               window.URL.revokeObjectURL(url);
            })
            .catch(error => console.log(error));
        },
    },
};
</script>

<style scoped>.container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 75%;
    /* 16:9 Aspect Ratio */
}

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
}</style>