<template>
  <div>
    <h1 class="text-3xl font-bold text-black pb-6">UMUMIY TAKLIFLAR JADVALI</h1>
    <table class="min-w-full">
      <thead class="bg-gray-800 text-white">
        <tr>
          <th class="px-4 py-2 w-1/2">Ismlar</th>
          <th class="px-4 py-2 w-1/2">Takliflar soni</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(name, index) in offerNames" :key="index">
          <td class="px-4 py-2">
            <div class=" flex space-x-2 justify-center" role="button">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round"
                  d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3" />
              </svg>
              <span class="hover:underline text-blue-700" @click="goToDetail(name.id)">
                {{ name.latinName }}
              </span>
            </div>
          </td>
          <td class="px-4 py-2">
            <span class="offers">{{ name.offerCount }}</span>
          </td>
          
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from '@/axiosInstance';
export default {
  name: 'MeaningOfNamesOffers',

  data() {
    return {
      offerNames: []
    };
  },
  created() {
      axios.get('/api/names/v1/offer_counts')
      .then(response => {
        this.offerNames = response.data;
      }).catch(error=>{
        console.log(error);
      })
  },

  methods: {
    goToDetail(nameId){
      this.$router.push({name:'name-offers', query:{id:nameId}})
    }
  },
};
</script>

<style scoped>
table {
  border-collapse: collapse;
}

th   {
  border: 1px solid #e2e8f0;
}
td{
  border-bottom: 1px solid  #e2e8f0;
}

.offers {
  padding: 8px 12px;
  border-radius: 50px;
  background-color: #BBDEFB;
  font-size: 14px;
  font-weight: bolder;
  color: #1976D2;
}
</style>