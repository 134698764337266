<template>
    <div class="p-4 lg:max-w-7xl lg:mx-auto pb-36">
        <MiniNavbar @search="getResultOfNamesBySearchValue" :search_item="searchItem" />
        <div class="space-y-2">
            <h1>
                <div class="capitalize font-bold dark:text-gray-700 text-3xl text-center">
                    <span v-for="rukn in ruknName" :key="rukn.id">{{ rukn }} </span>
                </div>
            </h1>
            <p>Ushbu sahifada <span class="text-black font-bold">{{ filterNames.length }}</span> ta - <span
                    class="text-black font-bold"><span v-for="rukn in ruknName" :key="rukn.id">{{ rukn }} </span></span>
                ismlar ro'yhati berilgan.</p>

            <Alphabet @letter_alpha="filteredByAlphabet" />

        </div>

        <!-- Snippet -->
        <section class="flex flex-col justify-center antialiased text-gray-600 min-h-screen p-4">
            <div class="h-full">

                <!-- <Pagination :list="filterNames" @paginatedNamesList="updateNamesList"/> -->
                <div v-for="name in filterNames" :key="name.id">
                    <!-- name.deleted = true isn't showed to user -->
                    <div v-if="!(name.deleted)"
                        class="rounded-lg p-8 my-4 rounded-2xl max-w-5xl mx-auto  shadow-lg rounded-lg"
                        :class="name.gender == 'WOMEN' ? 'bg-red-100' : 'bg-gray-200'">

                        <!-- Card header -->
                        <div class="w-full flex justify-between items-center mb-3">
                            <!-- Title -->

                            <!-- <router-link to="/detail?id={name.id}">  -->
                            <span role="button" class="font-bold text-3xl hover:underline"
                                :class="name.gender == 'WOMEN' ? 'text-red-500 ' : 'text-blue-500 '"
                                @click="goToDetail(name.id)">{{
                                    name.latinName }}</span>
                            <!-- </router-link> -->

                            <!-- Like and comment buttons -->
                            <div class="flex-shrink-0 flex items-center space-x-3 sm:ml-2">
                                <div
                                    class="flex items-center text-left text-sm font-medium text-gray-500 hover:text-black group focus:outline-none focus-visible:border-b focus-visible:border-indigo-100">
                                    <svg class="w-4 h-4 flex-shrink-0 mr-2 fill-current text-gray-500 group-hover:text-black"
                                        viewBox="0 0 16 16">
                                        <path
                                            d="M14.682 2.318A4.485 4.485 0 0 0 11.5 1 4.377 4.377 0 0 0 8 2.707 4.383 4.383 0 0 0 4.5 1a4.5 4.5 0 0 0-3.182 7.682L8 15l6.682-6.318a4.5 4.5 0 0 0 0-6.364Zm-1.4 4.933L8 12.247l-5.285-5A2.5 2.5 0 0 1 4.5 3c1.437 0 2.312.681 3.5 2.625C9.187 3.681 10.062 3 11.5 3a2.5 2.5 0 0 1 1.785 4.251h-.003Z" />
                                    </svg>
                                    <span>{{ name.likeCount }}</span>
                                </div>
                                <div
                                    class="flex items-center text-left text-sm font-medium text-gray-500 hover:text-black group focus:outline-none focus-visible:border-b focus-visible:border-indigo-100">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="mr-2">
                                        <path
                                            d="M9.73438 7.03125C9.82812 7.03125 9.92188 7 10.0156 7C11.6719 7 13.0156 8.34375 13.0156 10C13.0156 11.6562 11.6719 13 10.0156 13C8.32812 13 7.01562 11.6562 7.01562 10C7.01562 9.9375 7.01562 9.84375 7.01562 9.75C7.29688 9.90625 7.64062 10 8.01562 10C9.10938 10 10.0156 9.125 10.0156 8C10.0156 7.65625 9.89062 7.3125 9.73438 7.03125ZM16.0156 5.53125C17.4844 6.875 18.4531 8.5 18.9219 9.625C19.0156 9.875 19.0156 10.1562 18.9219 10.4062C18.4531 11.5 17.4844 13.125 16.0156 14.5C14.5469 15.875 12.5156 17 10.0156 17C7.48438 17 5.45312 15.875 3.98438 14.5C2.51562 13.125 1.54688 11.5 1.07812 10.4062C0.984375 10.1562 0.984375 9.875 1.07812 9.625C1.54688 8.5 2.51562 6.875 3.98438 5.53125C5.45312 4.15625 7.48438 3 10.0156 3C12.5156 3 14.5469 4.15625 16.0156 5.53125ZM10.0156 5.5C7.51562 5.5 5.51562 7.53125 5.51562 10C5.51562 12.5 7.51562 14.5 10.0156 14.5C12.4844 14.5 14.5156 12.5 14.5156 10C14.5156 7.53125 12.4844 5.5 10.0156 5.5Z">
                                        </path>
                                    </svg>
                                    <span v-if="name.seenCount === 0">1</span>
                                    <span v-else>{{ name.seenCount }}</span>
                                    
                                </div>
                            </div>
                        </div>

                        <div class="pt-2 text-left">

                            <h5 class="flex text-xl text-gray-700">{{ name.latinDesc }}</h5>

                            <div class="flex justify-between mt-4">
                                <div class="flex items-center space-x-2 hover:text-black text-gary-500 text-md">
                                    <!-- click to like -->
                                    <button @click="clickLike(name.id)"
                                        :class="isFillButton(name.id) ? 'bg-yellow-500' : ''"
                                        class="inline-flex items-center justify-center w-8 h-8  transition-colors duration-150 hover:bg-yellow-500 rounded-full focus:shadow-outline"
                                        style="border:1px solid black">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                            <path
                                                d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                                        </svg>
                                    </button> <span class="bg-white px-2 text-red-300 rounded-xl">#like</span>
                                    <span class="bg-white px-2 text-yellow-500 rounded-xl">#{{ name.rukn.toLowerCase()
                                    }}</span>
                                    <!-- click to like end -->
                                </div>
                                <button @click="goToDetail(name.id)"
                                    :class="name.gender == 'WOMEN' ? 'bg-red-600 hover:bg-red-800' : 'bg-blue-600  hover:bg-blue-800'"
                                    class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white rounded-lg">
                                    Batafsil
                                    <svg aria-hidden="true" class="w-4 h-4 ml-2 -mr-1" fill="currentColor"
                                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    </div>
</template>

<script>
import Alphabet from '../Alphabet.vue';
import MiniNavbar from '../MiniNavbar.vue';
import axios from '@/axiosInstance';
import { eventBus } from '@/store/eventBus'

export default {
    name: 'NamesListVue',
    components: {
        Alphabet,
        MiniNavbar,
    },
    data() {
        return {
            namesList: [],
            paginatedNamesList: [],
            filterNames: [],
            searchItem: '',
            ruknName: ['aralash'],
            alpha: ''
        };
    },

    async created() {
        this.getDataByROG()
    },

    computed: {
        isFillButton() {
            return (nameId) => {
                const likedNamesList = JSON.parse(localStorage.getItem('likedNames'))||[];
                if (likedNamesList.includes(nameId)) {
                    return true;
                    
                } else {
                    return false;
                }
            }
        },
    },

    methods: {
        goToDetail(id) {
            this.$router.push({ name: "detail", query: { name: id } });
        },
        updateNamesList(child_data) {
            this.paginatedNamesList = child_data;
        },
        filteredByAlphabet(letter_alpha) {
            // this.$router.push({ name: 'names-list' })
            const queryParams = { ...this.$route.query };
            queryParams['filterBy'] = letter_alpha;
            this.$router.push({ query: queryParams });
            this.alpha = letter_alpha;
            this.filterNames = this.namesList.filter((name) => name.latinName.toUpperCase().startsWith(letter_alpha.toUpperCase()));
        },

        clickLike(nameId) {
            let likedNamesList = JSON.parse(localStorage.getItem('likedNames')) || [];

            if (!likedNamesList.includes(nameId)) {
                axios.post(`/api/names/v1/${nameId}/like`)
                    .then(response => {
                        likedNamesList.push(nameId);
                        localStorage.setItem('likedNames', JSON.stringify(likedNamesList));
                        console.log(response.data, nameId);
                        this.getDataByROG();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            } else {
                axios.post(`/api/names/v1/${nameId}/like_undo`)
                    .then(response => {
                        likedNamesList = likedNamesList.filter(likedName => likedName !== nameId);
                        localStorage.setItem('likedNames', JSON.stringify(likedNamesList));
                        console.log(response.data, nameId, 'liked Name');
                        this.getDataByROG();
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        },

        async getAllData() {
            const { data } = await axios.get(`/api/names/v1`);
            this.filterNames = data;
            this.namesList = data;
            if (this.alpha) {
                this.filterNames = this.namesList.filter((name) => name.latinName.toUpperCase().startsWith(this.alpha.toUpperCase()));
            }
        },
        async getDataByROG() {
            const { searchValue, rukn, gender } = this.$route.query;
            if (searchValue) {
                this.searchItem = searchValue;
                this.getResultOfNamesBySearchValue(searchValue);
            } else if (rukn) {
                let response = await axios.get(`/api/names/v1/get/rukn?rukn=${rukn}`);
                if (gender) {
                    response = await axios.get(`/api/names/v1/get/rukns?rukn=${rukn}&gender=${gender}`);
                }
                this.ruknName = eventBus.rukns[rukn];
                this.filterNames = response.data;
                this.namesList = response.data;
            } else {
                this.getAllData();
            }
            if (this.alpha) {
                this.filterNames = this.namesList.filter((name) => name.latinName.toUpperCase().startsWith(this.alpha.toUpperCase()));
            }
        },
        async getResultOfNamesBySearchValue(value) {
            const response = await axios.get(`/api/names/v1/search?search=${value}`);
            this.namesList = response.data;
            this.filterNames = this.namesList.filter((name) => name.latinName.toUpperCase().startsWith(this.alpha.toUpperCase()));
        },


    },
};
</script>

<style scoped></style>