<template>
    <div>
        <h1 class="text-3xl font-bold text-black pb-6">MAQOLALAR JADVALI</h1>
        <HeaderTable @search="getResultOfNamesBySearchValue" :linkTo = "'create-article'"/>
        <table class="min-w-full">
            <thead class="bg-gray-800 text-white">
                <tr>
                    <th class="px-4 py-2 w-1/6">#</th>
                    <th class="px-4 py-2 w-1/5">Image</th>
                    <th class="px-4 py-2 w-1/5">Title/file-PDF</th>
                    <th class="px-4 py-2 w-1/2">Author</th>
                    <th class="px-4 py-2 w-1/2">Status</th>

                    <th class="px-4 py-2 w-1/2">createdDate</th>
                    <th class="px-4 py-2 w-1/2">lastModifiedDate</th>
                    <th class="px-4 py-2 w-1/2">Actions</th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr v-for="(item, index) in paginatedNames" :key="item.id">

                    <td>{{ index + 1 }}</td>
                    <td><img :src="getImageByHashId(item.imageHashId)" alt="art cover" loading="lazy"
                            class="h-[80px] w-[146px] object-cover mx-auto" /></td>
                    <td class="w-1/2">
                        <button class="hover:underline hover:text-blue-400" @click="getDocumentByHashId(item.articleHashId)">
                            {{ item.title }}
                        </button> 
                    </td>
                    <td>{{ item.author }}</td>
                    <td>{{ item.deleted }} </td>
                    <td>{{ getCreatedDate(item.createdDate) }}</td>
                    <td>{{ getCreatedDate(item.lastModifiedDate) }}</td>
                    <td>
                        <div @click="updateArticle(item.id)" role="button" class="font-medium text-yellow-500">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class=" mx-auto w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                            </svg>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
        <br>
        <Pagination v-if="articles.length > 0 && isSearchEmpty" :list="articles" :number="4" @paginated="getPaginatedList" />
    </div>
</template>

<script>
import axios from '@/axiosInstance';
import Pagination from '@/components/Pagination.vue';
import HeaderTable from '@/components/HeaderTable.vue';
export default {
    name: 'ArticlesVue',
    components: {
        Pagination,
        HeaderTable
    },
    data() {
        return {
            articles: [],
            paginatedNames: [],
            isSearchEmpty:true,
        };
    },
    created() {
        axios.get('/api/articles/v1').then(response => {
            this.articles = response.data.reverse();
        }).catch(error => console.log(error))
    },
    computed: {
        getImageByHashId() {
            return (id) => {
                const imagePath = `/api/files/v1/get/${id}`
                return axios.defaults.baseURL + imagePath

            }
        },
        getCreatedDate() {
            return (time) => {
            const date = new Date(time);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = String(date.getFullYear()).slice(2);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');

            return `${day}-${month}-${year} ${hours}:${minutes}`;
            }   
        }
    },
    methods: {
        async getResultOfNamesBySearchValue(value) {
            if (value){
                console.log(value);
                const response = await axios.get(`/api/articles/v1/search?query=${value}`);
                this.paginatedNames = response.data;
                this.isSearchEmpty = false;
            } else{
                this.isSearchEmpty = true;
            }
        },
        updateArticle(id) {
            this.$router.push({ name: 'update-article', query: { articleId: id } })
        },
        getPaginatedList(list) {
            console.log(list)
            this.paginatedNames = list;
            // if (this.articles.length > 0) {
            //     console.log(this.articles)
            //     this.paginatedNames = this.articles.slice(list[0], list[1])
            //     console.log(this.paginatedNames)
            // }
        },
        getDocumentByHashId(id){
            axios.get(`/api/files/v1/get/${id}`)
        }

    }


};
</script>

<style scoped></style>