<template>
  <div class="relative">
    <!-- Selected items display -->
    <div class="p-2 bg-white rounded border border-gray-300 flex flex-wrap gap-1" v-if="selectedItems.length > 0">
      <div class="px-2 py-1 bg-gray-200 rounded flex items-center justify-center" v-for="item in selectedItems"
        :key="item">
        {{ item }}
        <button class="ml-1 focus:outline-none" @click="removeItem(item)">
          <svg class="w-3 h-3 text-gray-600" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            viewBox="0 0 24 24" stroke="currentColor">
            <path d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
    </div>

    <!-- Dropdown menu -->
    <div class="relative">
      <button class="p-2 bg-white rounded border border-gray-300 w-full text-left flex justify-between items-center"
        @click="toggleDropdown" @mousedown.stop>
        {{ dropdownLabel }}
        <svg :class="{
          'transform rotate-180': isDropdownOpen,
          'transform rotate-0': !isDropdownOpen
        }" class="w-4 h-4 text-gray-600" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          viewBox="0 0 24 24" stroke="currentColor">
          <path d="M19 9l-7 7-7-7"></path>
        </svg>
      </button>

      <div v-show="isDropdownOpen" class="absolute mt-1 w-full bg-white rounded border border-gray-300 shadow-lg">
        <input type="text" v-model="filter" placeholder="Qidirish..."
          class="p-2 px-3 bg-gray-100 border-b border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 w-full" />

        <div class="flex flex-wrap p-2">
          <label class="inline-flex items-center m-1 px-2 py-1 rounded border border-gray-300 bg-gray-100"
            v-for="item in filteredItems" :key="item">
            <input type="checkbox" class="form-checkbox h-4 w-4 text-blue-500" :value="item" v-model="selectedItems" />
            <span class="ml-2 text-gray-700">{{ item }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { eventBus } from '@/store/eventBus';
export default {
  name: "MultiSelect",
  props: {
    updateRukn: String,
  },
  data() {
    return {
      isDropdownOpen: false,
      items: ["arabcha", "o'zbekcha", "turkcha", "forscha", "tatarcha", "pahloviycha", "xitoycha", 'ruscha', "mo'g'ulcha", "fors-tojikcha", "yunoncha", "so'g'dcha", 'suriyoncha', "hindcha", "yahudiycha", "qadimiy turkcha", "qadimiy yunoncha", "qadimiy yahudiycha", "qadimiy eroncha"],
      selectedItems: [],
      filter: ""
    };
  },

  computed: {
    dropdownLabel() {
      if (this.selectedItems.length === 0) {
        return "Rukn tanlang";
      } else if (this.selectedItems.length === 1) {
        return this.selectedItems[0];
      } else {
        return `${this.selectedItems.length} ta rukn tanlandi `;
      }
    },
    filteredItems() {
      return this.items.filter(item =>
        item.toLowerCase().includes(this.filter.toLowerCase())
      );
    }
  },
  created() {
    if (this.updateRukn) {
      this.selectedItems = eventBus.rukns[this.updateRukn]
    }
  },
  methods: {
    toggleDropdown(event) {
      event.preventDefault(); // Prevent the default behavior of the event
      event.stopPropagation(); // Stop event propagation
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    removeItem(item) {
      const index = this.selectedItems.indexOf(item);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
      }
    },
    updateSelectedItems() {
      this.$emit('change', this.selectedItems);
    }
  },

  watch: {
    //In this example, a watch object is added to the component options. Inside the watch object, we define a watcher for the selectedItems property. Whenever the selectedItems array changes, the watcher function is triggered.
    selectedItems() {
      this.updateSelectedItems();
    }
  }
};
</script>
  