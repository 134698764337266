import Vue from "vue";
import VueRouter from "vue-router";
import AboutUs from '@/components/pages/AboutUs'
import NamesList from '@/components/pages/NamesList'
import NamesListND from '@/components/pages/NamesListND'
import Genders from '@/components/pages/Genders'
import NameDetail from '@/components/pages/NameDetail'
import OffersVue from '@/components/pages/Offers'
import HomeVue from '@/components/pages/Home'
import MostSearched from '@/components/pages/MostSearched'
import MainLandingPage from '@/components/pages/MainLandingPage'
import Article from '@/components/pages/Article'
import ArticleDetail from '@/components/pages/ArticleDetail'
import LoginAdmin from '@/components/pages/LoginAdmin'

import Dashboard from '@/components/pages/admin/Dashboard'
import AdminLanding from '@/components/pages/admin/AdminLanding'
import Settings from '@/components/pages/admin/Settings'
import NamesTable from '@/components/pages/admin/names/NamesTable'
import CreateName from '@/components/pages/admin/names/CreateName'
import UpdateName from '@/components/pages/admin/names/UpdateName'
import Offers from '@/components/pages/admin/offers/Offers'
import NameOffers from '@/components/pages/admin/offers/NameOffers'
import UpdateOffer from '@/components/pages/admin/offers/UpdateOffer'
import Articles from '@/components/pages/admin/articles/Articles'
import CreateArticle from '@/components/pages/admin/articles/CreateArticle'
import UpdateArticle from '@/components/pages/admin/articles/UpdateArticle'

Vue.use(VueRouter);

const routes = [
  {
    path: '/about',
    name: 'about',
    component: AboutUs,
  },
  {
    path: '/admin-signin',
    name: 'signin',
    component: LoginAdmin,
  },
  {
    path: '/admin-dashboard',
    component: Dashboard,
    children: [
      {
        path: '/admin-dashboard',
        name: 'dashboard',
        component: AdminLanding,
      },
      {
        path: '/names-table',
        name: 'names',
        component: NamesTable,
      },
      {
        path: '/name-create',
        name: 'createName',
        component: CreateName,
      },
      {
        path: '/name-update',
        name: 'updateName',
        component: UpdateName,
      },

      {
        path: '/settings',
        name: 'settings',
        component: Settings,
      },
      {
        path: '/offers',
        name: 'offers',
        component: Offers,
      },
      {
        path: '/update-offer',
        name: 'update-offer',
        component: UpdateOffer,
      },
      {
        path: '/name-offers',
        name: 'name-offers',
        component: NameOffers,
      },
      {
        path: '/articles',
        name: 'articles',
        component: Articles,
      },
      {
        path: '/create-article',
        name: 'create-article',
        component: CreateArticle,
      },
      {
        path: '/update-article',
        name: 'update-article',
        component: UpdateArticle,
      },
    ]
  },
  {
    path: '/',
    component: MainLandingPage,
    children: [
      {
        path: '/',
        name: 'home',
        component: HomeVue,
      },
      {
        path: '/names-list',
        name: 'names-list',
        component: NamesList,
      },
      {
        path: '/names-list/detail',
        name: 'detail',
        component: NameDetail,
      },
      {
        path: '/gender',
        name: 'gender',
        component: Genders,
      },
      {
        path: '/gender/names-list',
        name: 'gender-names-list',
        component: NamesListND,
      },
      {
        path: '/offer',
        name: 'offer',
        component: OffersVue,
      },
      {
        path: '/article',
        name: 'article',
        component: Article,
      },
      {
        path: '/article/detail',
        name: 'article-detail',
        component: ArticleDetail,
      },
      {
        path: '/most-searched',
        name: 'most-searched',
        component: MostSearched,
      },
      
    ]
  }

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router;