<template>
    <div class="p-4 lg:max-w-7xl lg:mx-auto pb-36">
        <div>
            <backspace-button :back_path="path_name" :query_obj="query" />
            <!-- bosh harflar buyicha -->
            <!-- by Alphabet -->
            <div v-if="gender_type">
                <h1
                    class="my-8 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-dark">
                    <span class="text-blue-600 dark:text-blue-500">Alfabet</span> bo'yicha qidiring
                </h1>

                <Alphabet @letter_alpha="filterNamesListND" />
            </div>
            <div v-else>
                <h1 class="text-2xl font-bold mb-2">{{ letter.toUpperCase() }}</h1>
                <div class="text-gray-500">
                    <p>harfidan boshlanadigan ismlar</p>
                </div>

                <div class="text-right overflow-x-auto -mx-4 my-4">

                    <div class="inline-flex px-4">

                        <div class="grid grid-cols-2 gap-2">


                            <div role="button"
                                class="bg-gray-100 py-2 px-4 rounded-full flex items-center space-x-1 border-secondary text-dark">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M9.23152 13.6875C9.60652 13.2812 9.57527 12.625 9.16902 12.25C8.76277 11.875 8.10652 11.9375 7.76277 12.3125L6.76277 13.4688V4.03125C6.76277 3.46875 6.29402 3 5.76277 3C5.20027 3 4.76277 3.46875 4.76277 4.03125V13.4688L3.73152 12.3438C3.35652 11.9375 2.73152 11.9062 2.32527 12.2812C1.91902 12.6562 1.88777 13.2812 2.26277 13.6875L4.98152 16.7188C5.35652 17.125 6.07527 17.125 6.45027 16.7188L9.23152 13.6875ZM15.7628 15H14.169L16.4503 12.7188C16.7315 12.4375 16.8253 12 16.669 11.625C16.5128 11.25 16.1378 11 15.7315 11H11.7315C11.2003 11 10.7628 11.4688 10.7628 12C10.7628 12.5625 11.2003 13 11.7628 13H13.3253L11.044 15.3125C10.7628 15.5938 10.669 16.0312 10.8253 16.4062C10.9815 16.7812 11.3565 17 11.7315 17H15.7003C16.294 17 16.7628 16.5625 16.7628 16C16.7628 15.4688 16.294 15 15.7628 15ZM17.1378 8.5625L14.6378 3.5625C14.294 2.875 13.2003 2.875 12.8565 3.5625L10.3565 8.5625C10.1065 9.0625 10.294 9.65625 10.794 9.90625C11.294 10.1562 11.8878 9.9375 12.1378 9.46875L12.3565 9H15.1378L15.3565 9.46875C15.544 9.84375 16.1065 10.1875 16.7003 9.90625C17.2003 9.65625 17.3878 9.0625 17.1378 8.5625ZM13.2315 7.25L13.7628 6.21875L14.2628 7.25H13.2315Z">
                                    </path>
                                </svg>
                                <span @click="sortedNames" >Alifbo harflar bo'yicha saralash</span>
                            </div>



                            <div role="button"
                                class="bg-gray-100 py-2 px-4 rounded-full flex items-center space-x-1 ">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                                    <path
                                        d="M9.73438 7.03125C9.82812 7.03125 9.92188 7 10.0156 7C11.6719 7 13.0156 8.34375 13.0156 10C13.0156 11.6562 11.6719 13 10.0156 13C8.32812 13 7.01562 11.6562 7.01562 10C7.01562 9.9375 7.01562 9.84375 7.01562 9.75C7.29688 9.90625 7.64062 10 8.01562 10C9.10938 10 10.0156 9.125 10.0156 8C10.0156 7.65625 9.89062 7.3125 9.73438 7.03125ZM16.0156 5.53125C17.4844 6.875 18.4531 8.5 18.9219 9.625C19.0156 9.875 19.0156 10.1562 18.9219 10.4062C18.4531 11.5 17.4844 13.125 16.0156 14.5C14.5469 15.875 12.5156 17 10.0156 17C7.48438 17 5.45312 15.875 3.98438 14.5C2.51562 13.125 1.54688 11.5 1.07812 10.4062C0.984375 10.1562 0.984375 9.875 1.07812 9.625C1.54688 8.5 2.51562 6.875 3.98438 5.53125C5.45312 4.15625 7.48438 3 10.0156 3C12.5156 3 14.5469 4.15625 16.0156 5.53125ZM10.0156 5.5C7.51562 5.5 5.51562 7.53125 5.51562 10C5.51562 12.5 7.51562 14.5 10.0156 14.5C12.4844 14.5 14.5156 12.5 14.5156 10C14.5156 7.53125 12.4844 5.5 10.0156 5.5Z">
                                    </path>
                                </svg>
                                <span @click="sortByView" >Ko'rishlar soni bo'yicha saralash</span>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <section class="flex flex-col justify-center antialiased text-gray-600 min-h-screen p-4">

            <div class="overflow-x-auto">
                <table v-if="namesList2.length != 0" class="table-auto min-w-full divide-y divide-gray-200">
                    <thead class="bg-gray-50">
                        <tr>
                            <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name
                            </th>
                            <th class="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Meaning
                            </th>
                            <th class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr v-for="(name, index) in namesList2" :key="name.id" class="hover:bg-neutral-100">
                            <td class="text-left whitespace-nowrap ">
                                <span class="hover:underline cursor-pointer"
                                    :class="name.gender == 'WOMEN' ? 'text-red-500 ' : 'text-blue-500 '"
                                    @click="goToDetail(name.id)">
                                    {{ name.latinName }}
                                </span>
                            </td>
                            <td class="px-6 py-4 text-center min-w-[200px]">{{ name.latinDesc }}</td>
                            <td class="px-6 py-4 text-right whitespace-nowrap">

                                <div v-if="gender_type" class="flex items-center justify-end">
                                    
                                        <button @click="clickLike(index)" 
                                            class="LikeBoss inline-flex items-center justify-center w-8 h-8 mr-2 text-pink-100 transition-colors duration-150 border-2 hover:border-black rounded-full focus:shadow-outline hover:bg-blue-500">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="1em"
                                            viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                                            <path
                                                d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z" />
                                        </svg>
                                        </button>
                                    
                                    <div v-if="name" class="LikeBossCount text-gray-500 fav-count" style="display: none;">{{ name.likeCount }}</div>
                                    
                                </div>
                                <div v-else class="flex items-center space-x-2 hover:text-black text-gary-500 text-md">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" class="inline">
                                        <path
                                            d="M9.73438 7.03125C9.82812 7.03125 9.92188 7 10.0156 7C11.6719 7 13.0156 8.34375 13.0156 10C13.0156 11.6562 11.6719 13 10.0156 13C8.32812 13 7.01562 11.6562 7.01562 10C7.01562 9.9375 7.01562 9.84375 7.01562 9.75C7.29688 9.90625 7.64062 10 8.01562 10C9.10938 10 10.0156 9.125 10.0156 8C10.0156 7.65625 9.89062 7.3125 9.73438 7.03125ZM16.0156 5.53125C17.4844 6.875 18.4531 8.5 18.9219 9.625C19.0156 9.875 19.0156 10.1562 18.9219 10.4062C18.4531 11.5 17.4844 13.125 16.0156 14.5C14.5469 15.875 12.5156 17 10.0156 17C7.48438 17 5.45312 15.875 3.98438 14.5C2.51562 13.125 1.54688 11.5 1.07812 10.4062C0.984375 10.1562 0.984375 9.875 1.07812 9.625C1.54688 8.5 2.51562 6.875 3.98438 5.53125C5.45312 4.15625 7.48438 3 10.0156 3C12.5156 3 14.5469 4.15625 16.0156 5.53125ZM10.0156 5.5C7.51562 5.5 5.51562 7.53125 5.51562 10C5.51562 12.5 7.51562 14.5 10.0156 14.5C12.4844 14.5 14.5156 12.5 14.5156 10C14.5156 7.53125 12.4844 5.5 10.0156 5.5Z">
                                        </path>
                                    </svg>
                                    <span v-if="name.seenCount === 0">{{ name.seenCount+1 }}</span>
                                    <span v-else>{{ name.seenCount }}</span>

                                </div>
                            </td>
                        </tr>

                        <!-- More rows... -->
                    </tbody>
                </table>
                <div v-else class="bg-red-400 p-4 rounded-xl text-white"><span class="font-bold text-black">{{ letter.toUpperCase() }}</span> harfi bo'yicha ism topilmadi :(
                </div>
            </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from '@/axiosInstance';
import Alphabet from '../Alphabet.vue';
import { _eventBus } from '@/store/eventBus'
import BackspaceButton from '../BackspaceButton.vue';
export default {
    name: 'NameListNDvue',
    components: {
        Alphabet,
        BackspaceButton,
    },
    data() {
        return {
            allNames: [],
            namesList2: [],
            letter: '',
            gender_type: '',
            path_name: 'gender',
            query: {},
        };
    },
    async created() {
        // event bus and emits are used for reload page
        const { gender, filterby } = this.$route.query;
        this.gender_type = gender;
        _eventBus.$on('footer-letter', this.handleEventNamesListND);
        this.letter = filterby;
        this.query.type = this.gender_type;
        let response = {};
        if (this.gender_type) {
            if (gender.toLowerCase() == 'women') {
                response = await axios.get("/api/names/v1/girls");
            } else if (gender.toLowerCase() == 'men') {
                response = await axios.get("/api/names/v1/boys");
            } else {
                console.log('invalid gender query!')
            }
        } else {
            response = await axios.get("/api/names/v1");
        }
        if (response.data) {
            this.allNames = response.data;
            this.namesList2 = this.allNames.filter((name) => name.latinName.toUpperCase().startsWith(filterby.toUpperCase()));
        }
    },
    destroyed() {
        _eventBus.$off('footer-letter', this.handleEventNamesListND);
    },
    methods: {
        goToDetail(id) {
            this.$router.push({ name: "detail", query: { name: id } });
        },
        filterNamesListND(filterBy) {

            this.$router.push({ name: 'gender-names-list', query: { gender: this.gender_type, filterby: filterBy } }); // SAVOLLI QISM : REDUNDANT NAVIGATION
            this.letter = filterBy;
            this.namesList2 = this.allNames.filter((name) => name.latinName.toUpperCase().startsWith(filterBy.toUpperCase()));
        },
        async handleEventNamesListND(letter_footer) {
            this.letter = letter_footer;
            this.gender_type = ''
            const response = await axios.get("/api/names/v1");
            this.allNames = response.data;
            this.namesList2 = this.allNames.filter((name) => name.latinName.toUpperCase().startsWith(this.letter.toUpperCase()));
        },
        sortedNames(){
            this.namesList2 = this.namesList2.sort((a,b)=> a.latinName.localeCompare(b.latinName));
        },
        sortByView(){
            this.namesList2 = this.namesList2.sort((a,b)=> b.seenCount - a.seenCount);
        },
        clickLike(index) {
            // axios.post(`/api/names/v1/${nameId}/like`);
            document.getElementsByClassName('LikeBoss')[index].style.backgroundColor = '#3B82F6';
            document.getElementsByClassName('LikeBossCount')[index].style.display = '';
        },

    },
};
</script>

<style scoped>
/* @media (max-width: 530px) {
    table{
        overflow-x: auto;
    }
} */
</style>