import Vue from 'vue';
const eventBus = new Vue(); // is used in name CRUD and store data 
const _eventBus = new Vue(); // is used in footer-letter
const signin = new Vue();

signin.log = {
    "name":'AdminName',
    "login" : 'ismlarimlo@gmail.com',
    "pswd" : 'ismlarimlo123'
};

eventBus.rukns = {
    "ARAB": ['arabcha'],
    "UZBEK": ["o'zbekcha"],
    "TURK": ['turkcha'],
    "TATARCHA": ['tatarcha'],
    "MUGUL": ['mo\'g\'ulcha'],
    "XITOY":['xitoycha'],
    "YUNON": ['yunoncha'],
    "SURIYON":['suriyoncha'],
    "SUGDCHA": ["so'g'dcha"],
    "YAHUD":['yahudiycha'],
    "PAHLOVIY":["pahloviycha"], 
    "FORS":['forscha'],
    "FORS_TOJIK": ['fors-tojikcha'],
    "QADIMIY_YAHUD": ['qadimiy yahudiycha'],
    "QADIMIY_TURK":['qadimiy turkcha'],

    "ARAB_UZBEK": ["arabcha", "o'zbekcha"],
    "ARAB_FORS_TOJIK": ['arabcha', 'fors-tojikcha'],
    "ARAB_YUNON": ['arabcha', 'yunoncha'],
    "ARAB_HIND": ['arabcha', 'hindcha'],
    "ARAB_TURK" : ['arabcha', 'turkcha'],
    "ARAB_YAHUD":['arabcha', 'yahudiycha'],
    "ARAB_FORS_TOJIK_UZBEK":['arabcha', 'fors-tojikcha', 'o\'zbekcha'],
    "ARAB_UZBEK_QADIMIY_YAHUD":['arabcha', 'o\'zbekcha','qadimiy yahudiycha'],


    "UZBEK_FORS_TOJIK": ["o'zbekcha", "fors-tojikcha"],
    "UZBEK_HIND": ["o'zbekcha", "hindcha"],
    "UZBEK_QADIMIY_TURKIY" : ["o'zbekcha", "qadimiy turkcha"],
    "UZBEK_RUS" : ["o'zbekcha", "ruscha"],
    "UZBEK_XITOY" : ["o'zbekcha", "xitoycha"],

    "FORS_TOJIK_HIND": ["fors-tojikcha", "hindcha"],
    "FORS_TOJIK_QADIMIY_YUNON": ["fors-tojikcha", "qadimiy yunoncha"],
    "FORS_TOJIK_QADIMIY_ERON": ["fors-tojikcha", "qadimiy eroncha"],
    "FORS_TOJIK_MUGUL":["fors-tojikcha", "mo'g'ulcha"],

    "YUNON_UZBEK": ["yunoncha", "o'zbekcha"],
    "YUNON_FORS_TOJIK": ["yunoncha", "fors-tojikcha"],

    "MUGUL_UZBEK" : ["mo'g'ulcha", "o'zbekcha"],
    "MUGUL_ARAB" : ["mo'g'ulcha", "arabcha"],

    "RUS_FORS_TOJIK": ['ruscha', "fors-tojikcha"],
    "RUS_ARAB" : ['ruscha', "arabcha"],

    "QADIMIY_YAHUD_UZBEK": ["qadimiy yahudiycha", "o'zbekcha"],
    "QADIMIY_YAHUD_ARAB": ["qadimiy yahudiycha", "arabcha"],
    "QADIMIY_YAHUD_FORS_TOJIK": ["qadimiy yahudiycha", "fors-tojikcha"],
    "QADIMIY_YAHUD_HIND":['qadimiy yahudiycha', 'hindcha'],
}

export {_eventBus , eventBus, signin }  ;
