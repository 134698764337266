<template>
    <div class="w-screen h-screen flex">
        <!-- Side bar -->
        <div class="leftside w-[300px] h-full bg-gray-200 text-white" v-show="showSideBar">
            <div class="h-[50px] bg-gray-900 flex justify-between items-center">
                <div class="px-[20px]">
                    <h3 class="font-bold text-xl">Admin Panel</h3>

                </div>
                <div class="cursor-pointer w-[30px] hidden"  v-show="showSideBar" @click="showSideBar = !showSideBar">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
            </div>
            <div class="h-[calc(100vh-50px)] bg-gray-800 py-[20px]">
                <div class="px-[20px] space-y-[10px] flex flex-col justify-between h-full">
                    <div class="flex flex-col space-y-[10px]">

                        <router-link :to="{ name: 'dashboard' }"
                            class="inline-flex items-center w-full text-sm py-[10px] px-[10px] hover:bg-white hover:text-gray-800 rounded-md font-medium border-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25" />
                            </svg>
                            Bosh sahifa
                        </router-link>
                        <router-link :to="{ name: 'names' }"
                            class="inline-flex items-center w-full text-sm py-[10px] px-[10px] hover:bg-white hover:text-gray-800 rounded-md font-medium border-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                            </svg>


                            Ismlar
                        </router-link>
                        <router-link :to="{ name: 'offers' }"
                            class="inline-flex items-center w-full text-sm py-[10px] px-[10px] hover:bg-white hover:text-gray-800 rounded-md font-medium border-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                            </svg>

                            Takliflar
                        </router-link>
                        <router-link :to="{ name: 'articles' }"
                            class="inline-flex items-center w-full text-sm py-[10px] px-[10px] hover:bg-white hover:text-gray-800 rounded-md font-medium border-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6 mr-2">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
                            </svg>

                            Maqolalar
                        </router-link>
                    </div>
                    <div class="h-[50px]">
                        <router-link :to="{ name: 'settings' }"
                            class="inline-flex items-center w-full text-sm py-[10px] px-[10px] hover:bg-white hover:text-gray-800 rounded-md font-medium border-gray-200">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                stroke="currentColor" class="w-6 h-6 mr-2 fillCurrent">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                            </svg>

                            Sozlamalar
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <!-- Main -->
        <div class="rightside h-full bg-gray-400" :class="showSideBar ? 'w-[calc(100%-300px)]' : 'w-full'">
            <div class="h-[50px] bg-gray-100 flex justify-between items-center shadow-sm px-[20px] w-full py-[10px] ">
                <!-- Toggle menu -->
                <div class="cursor-pointer w-[30px]" v-show="!showSideBar" @click="showSideBar = !showSideBar">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>

                </div>
                <div class="cursor-pointer w-[30px]"  v-show="showSideBar" @click="showSideBar = !showSideBar">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </div>
                <!-- search-navbar -->
                <!-- <div class="w-[calc(100%-200px)] flex justify-center">
                    <form class="flex items-center w-[500px]">
                        <label for="voice-search" class="sr-only">Search</label>
                        <div class="relative w-full">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <input type="text" id="voice-search"
                                class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2 dark:border-gray-600 dark:placeholder-gray-400"
                                placeholder="Qidirish..." required>
                            <button type="button" class="absolute inset-y-0 right-0 flex items-center pr-3">
                                <svg aria-hidden="true"
                                    class="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd"
                                        d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div> -->

                <div class="w-[200px]">
                    <!-- User-Profile-Dropdown-->
                    <div class="px-4 py-3 text-sm font-bold flex items-center">
                        <img v-if="imageUrl" :src="imageUrl" id="avatarButton" type="button"
                            data-dropdown-toggle="userDropdown" data-dropdown-placement="bottom-start"
                            class="w-10 h-10 rounded-full cursor-pointer " alt="User dropdown"
                            @click="userDropdown = !userDropdown">

                        <img v-else id="avatarButton" type="button" data-dropdown-toggle="userDropdown"
                            data-dropdown-placement="bottom-start" class="w-10 h-10 rounded-full cursor-pointer"
                            src="../../../assets/admin/admin.png" alt="User dropdown" @click="userDropdown = !userDropdown">

                        <div>{{ admin['name'] }} <br>
                            <div class="font-medium truncate">{{ admin['login'] }}</div>
                        </div>

                    </div>
                    <!-- Dropdown menu --> <!-- DIQQAT HALI ISHLAMAYAPTI !!!-->
                    <div id="userDropdown"
                        class="z-10 absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:divide-gray-600 border"
                        :class="{ 'hidden': !userDropdown }">
                        <ul class="py-2 text-sm" aria-labelledby="avatarButton">
                            <li>
                                <router-link :to="{ name: 'dashboard' }"
                                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Bosh
                                    sahifa</router-link>
                            </li>
                            <li>
                                <router-link :to="{ name: 'settings' }"
                                    class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sozlamalar</router-link>
                            </li>
                        </ul>
                        <div class="py-1">
                            <div @click="signOut"
                                class="block px-4 py-2 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                                Chiqish</div>
                        </div>
                    </div>

                </div>


            </div>
            <div class="h-[calc(100vh-50px)] bg-gray-50 p-[20px]">
                <div class="border border-gray-300 rounded-md p-[20px] h-full  overflow-y-scroll">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { signin } from '@/store/eventBus'
import axios from '@/axiosInstance';
export default {
    name: 'MeaningOfNamesDashboard',

    data() {
        return {
            showSideBar: true,
            userDropdown: false,
            admin: signin.log,
            imageUrl: ''
        };
    },
    created() {
        if (!localStorage.getItem('isHaveAdmin')) {
            this.$router.push({ name: 'signin' })
        }

        axios.get('http://213.230.110.100:8090/api/files/v1/get/f8916277-c61e-48a9-9730-0b0481a7a043').then(response => {
            if (response.status == 200) {
                this.imageUrl = "http://213.230.110.100:8090/api/files/v1/get/f8916277-c61e-48a9-9730-0b0481a7a043";
            } else {
                this.imageUrl = '';
            }
        }).catch(error => console.log(error))



    },
    methods: {
        signOut() {
            localStorage.removeItem("isHaveAdmin")
            this.$router.push({ name: 'signin' })
        }
    },
};
</script>

<style scoped>
@media (max-width:550px) {
    .leftside {
        /* display: none !important; */
        position: absolute;
    }
    .leftside .hidden{
        display: block;
    }

    .rightside {
        width: 100%;
    }

}</style>