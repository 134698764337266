<template>
    <div>
        <div class="p-4 bg-gray-200 rounded-2xl">
            <div class="flex mb-4 gap-2">

                <ul class="list-none text-cyan-500 w-1/2"> 
                    <span class="font-bold text-xl">🚹O'G'IL BOLALAR</span>
                    
                    <li v-for="boy in topBoysName" :key="boy.id" class="">
                        <router-link :to="{ name: 'detail', query: { name: boy.id } }" class="inline-block py-2 hover:underline">
                            {{ boy.latinName }}
                        </router-link>
                    </li>
                    

                </ul>
                <ul class="list-none text-purple-500 w-1/2">
                    <span class="font-bold text-xl">🚺QIZ BOLALAR</span>
                    
                    <li v-for="girl in topGirlsName" :key="girl.id" class="">
                        <router-link :to="{ name: 'detail', query: { name: girl.id } }" class="inline-block py-2 hover:underline">
                            {{ girl.latinName }}
                        </router-link>
                    </li>
                    

                </ul>

            </div>

            <!-- <router-link :to="{name: 'most-searched'}" class="block py-2 px-4 rounded-full bg-white text-center"> <span class="font-extrabold text-blue-500"> Eng ko'p qidirilgan ismlar </span></router-link> -->
        </div>
    </div>
</template>

<script>
import axios from '@/axiosInstance';
export default {
    name: 'MostWantedVue',

    data() {
        return {
            topBoysName: [],
            topGirlsName: [],
        };
    },
    created(){
        axios.get(`/api/names/v1/boys`).then(response=>{
            this.topBoysName = response.data.sort((a,b)=>{
                return b.seenCount - a.seenCount
            }).slice(0,5)
            
        }).catch(error=>console.log(error));
        
        axios.get(`/api/names/v1/girls`).then(response=>{
            this.topGirlsName = response.data.sort((a,b)=>{
                return b.seenCount - a.seenCount
            }).slice(0,5)
        }).catch(error=>console.log(error));
    },
    methods: {

    },
};
</script>

<style scoped></style>