<template>
    <div>
        <div class="flex items-center justify-between">
            <router-link to="/" class="flex items-center space-x-2">
                <div class="flex items-center justify-center bg-gray-700  hover:bg-gray-900  rounded-lg w-10 h-10 text-white"
                    aria-label="Bosh sahifa">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            d="M18.9821 10C18.9821 10.5625 18.5133 11 17.9821 11H16.9821L17.0133 16C17.0133 16.0938 17.0133 16.1875 17.0133 16.25V16.75C17.0133 17.4688 16.4508 18 15.7633 18H15.2633C15.2008 18 15.1696 18 15.1383 18C15.1071 18 15.0446 18 15.0133 18H13.2633C12.5446 18 12.0133 17.4688 12.0133 16.75V14C12.0133 13.4688 11.5446 13 11.0133 13H9.01331C8.45081 13 8.01331 13.4688 8.01331 14V16.75C8.01331 17.4688 7.45081 18 6.76331 18H5.01331C4.95081 18 4.91956 18 4.85706 18C4.82581 18 4.79456 18 4.76331 18H4.26331C3.54456 18 3.01331 17.4688 3.01331 16.75V13.25C3.01331 13.25 3.01331 13.2188 3.01331 13.1875V11H2.01331C1.45081 11 1.01331 10.5625 1.01331 10C1.01331 9.71875 1.10706 9.46875 1.32581 9.25L9.32581 2.28125C9.54456 2.0625 9.79456 2 10.0133 2C10.2321 2 10.4821 2.09375 10.6696 2.25L18.6383 9.25C18.8883 9.46875 19.0133 9.71875 18.9821 10Z" />
                    </svg>
                </div>

                <p class="font-bold text-xl">Ismlar-Imlo</p>
            </router-link>
            <button @click="backToPathName"
                class="inline-flex items-center px-4 py-2 bg-gray-700 hover:bg-gray-900 text-white text-sm font-medium rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
                </svg>
                Orqaga
            </button>

        </div>
    </div>
</template>

<script>
export default {
    name: 'BackspaceButtonVue',
    // props: {
    //     back_path: String,
    //     query_obj: Object,
    // },
    data() {
        return {
        };
    },

    created() {
    },

    methods: {
        backToPathName() {
            this.$router.go(-1)
        }

    },
};
</script>

<style scoped></style>