<template>
    <div>
        <div class="flex justify-between gap-2 mb-4">
            <div>
                <router-link to="/" class="flex items-center justify-center bg-gray-700 rounded-lg w-10 h-10 text-white"
                    aria-label="Bosh sahifa">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                        <path
                            d="M18.9821 10C18.9821 10.5625 18.5133 11 17.9821 11H16.9821L17.0133 16C17.0133 16.0938 17.0133 16.1875 17.0133 16.25V16.75C17.0133 17.4688 16.4508 18 15.7633 18H15.2633C15.2008 18 15.1696 18 15.1383 18C15.1071 18 15.0446 18 15.0133 18H13.2633C12.5446 18 12.0133 17.4688 12.0133 16.75V14C12.0133 13.4688 11.5446 13 11.0133 13H9.01331C8.45081 13 8.01331 13.4688 8.01331 14V16.75C8.01331 17.4688 7.45081 18 6.76331 18H5.01331C4.95081 18 4.91956 18 4.85706 18C4.82581 18 4.79456 18 4.76331 18H4.26331C3.54456 18 3.01331 17.4688 3.01331 16.75V13.25C3.01331 13.25 3.01331 13.2188 3.01331 13.1875V11H2.01331C1.45081 11 1.01331 10.5625 1.01331 10C1.01331 9.71875 1.10706 9.46875 1.32581 9.25L9.32581 2.28125C9.54456 2.0625 9.79456 2 10.0133 2C10.2321 2 10.4821 2.09375 10.6696 2.25L18.6383 9.25C18.8883 9.46875 19.0133 9.71875 18.9821 10Z" />
                    </svg>
                </router-link>
            </div>
            <form class="flex items-center w-full">
                <label for="voice-search" class="sr-only">Search</label>
                <div class="relative w-full">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor"
                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </div>
                    <input type="text" id="voice-search" v-model="local_search_item" @input="sendSearchData"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Qidirish..." required>
                    <button type="button" class="absolute inset-y-0 right-0 flex items-center pr-3">
                        <svg aria-hidden="true"
                            class="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                            fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M7 4a3 3 0 016 0v4a3 3 0 11-6 0V4zm4 10.93A7.001 7.001 0 0017 8a1 1 0 10-2 0A5 5 0 015 8a1 1 0 00-2 0 7.001 7.001 0 006 6.93V17H6a1 1 0 100 2h8a1 1 0 100-2h-3v-2.07z"
                                clip-rule="evenodd"></path>
                        </svg>
                    </button>

                </div>
                <button type="submit" :disabled="local_search_item" @click="sendSearchData"
                    class="inline-flex items-center py-2.5 px-3 ml-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <svg aria-hidden="true" class="w-5 h-5 mr-2 -ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>Qidirish
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MiniNavbar',
    // props: {
    //     search_item : String,
    // },
    data() {
        return {
            local_search_item: "",
        };
    },

    // computed:{
    //     getSearchValue(){
    //         const {searchValue} = this.$route.query;
    //         return searchValue;
    //     }
    // },

    // created() {
    //     this.local_search_item = this.getSearchValue;
    // },
    
    methods: {
        async sendSearchData(event){
            event.preventDefault();
            
            this.$emit('search', this.local_search_item);
        }
    },
};
</script>

<style scoped>

</style>