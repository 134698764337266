<template>
    <div class="p-4 lg:max-w-7xl lg:mx-auto">
        <BackspaceButton />
        <div class="container py-4">
            <div class="text-left mb-3 px-2">
                <h1 class="mb-4 h3 text-xl" style="font-size: 26px;
                    font-weight: bold;
                    line-height: 32px;
                    color: #000000;
                    margin-top: 20px;">
                    {{ article.title }}</h1>
                <span class="text-muted flex space-x-2">
                    <span class="mr-2 mr-md-3 flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                        </svg>
                        {{ getCreatedDate(article.createdDate) }} </span>
                    <span class="text-nowrap flex">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-5 h-5 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                        </svg>

                        {{ article.author }} </span>
                </span>

            </div>
            <div class="px-8 md:px-0">
                <div class="text-black-500 italic" style="font-size: 19px;
                    line-height: 31px;">
                    {{ article.desc }}
                </div>
                <div class="my-3 flex items-center justify-center">
                    <img width="500" height="400" :src="getImageByHashId(article.imageHashId)"
                        class="attachment-twentyseventeen-featured-image size-twentyseventeen-featured-image wp-post-image"
                        alt="" loading="lazy" sizes="100vw">
                </div>
                <div class="text-justify py-2 md:px-[50px] leading-2 tracking-wide">
                    {{ article.text }}
                </div>
                <div class="w-full mx-auto py-4 lg:w-1/2 mt-6 lg:mt-0 lg:px-4 text-center md:text-left">
                    <button @click="getDocumentByHashId(article.articleHashId)"
                        class="flex justify-center space-x-2 w-full bg-gray-300 hover:bg-green-400 hover:text-white text-gray-800 font-bold py-2 px-4 rounded items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                        </svg>


                        <span>Yuklab olish</span>
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import BackspaceButton from '@/components/BackspaceButton.vue'
import axios from '@/axiosInstance'
export default {
    name: 'ArticleDetail',
    components: {
        BackspaceButton,
    },
    data() {
        return {
            article: {}
        };
    },
    computed: {
        getImageByHashId() {
            return (id) => {
                const imagePath = `/api/files/v1/get/${id}`
                return axios.defaults.baseURL + imagePath

            }
        },
        getCreatedDate() {
            return (time) => {
                const date = new Date(time);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear()).slice(2);
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');

                return `${day}-${month}-${year} ${hours}:${minutes}`;
            }
        }
    },

    created() {
        console.log(this.$route.query.id)
        axios.get(`/api/articles/v1/get/${this.$route.query.id}`).then(response => {
            this.article = response.data;
            console.log(this.article)
        }).catch(error => console.log(error))
    },
    methods: {
        getDocumentByHashId(id) {
            axios.get(`/api/files/v1/get/${id}`, { responseType: 'arraybuffer' })
            .then(response => {
               const blob = new Blob([response.data], { type: 'application/pdf' });
               const url = window.URL.createObjectURL(blob);

               const hiddenElement = document.createElement('a');
               hiddenElement.href = url;
               hiddenElement.target = '_blank';
               hiddenElement.download = `${id}.pdf`;
               hiddenElement.click();

               window.URL.revokeObjectURL(url);
            })
            .catch(error => console.log(error));
      },
    },
};
</script>

<style scoped></style>