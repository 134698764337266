<template>
    <div>
        <NotificationVue :notify="notify" :status="status" />
        <div class="flex justify-center">
            <h1 class="text-3xl text-black font-bold flex items-center pb-6">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    class="w-9 h-9 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                ADMIN SOZLAMALARI
            </h1>
        </div>
        <div class="container p-10 w-3/4 space-y-4 text-left">
            <div class="mb-4">
                <label for="large-input" class="block flex items-center  mb-2 text-md font-medium text-gray-900"><svg
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M9.568 3H5.25A2.25 2.25 0 003 5.25v4.318c0 .597.237 1.17.659 1.591l9.581 9.581c.699.699 1.78.872 2.607.33a18.095 18.095 0 005.223-5.223c.542-.827.369-1.908-.33-2.607L11.16 3.66A2.25 2.25 0 009.568 3z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 6h.008v.008H6V6z" />
                    </svg>
                    Ismi</label>

                <input v-model="data.name" type="text" id="small-input" required
                    class="block w-full p-3 text-gray-900 border border-gray-300 rounded-lg text-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter name...">
            </div>
            <div class="mb-4">
                <label for="large-input" class="block flex items-center mb-2 text-md font-medium text-gray-900"><svg
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                    </svg>
                    Elektron pochta</label>

                <input v-model="data.desc" type="email" id="small-input" required
                    class="block w-full p-3 text-gray-900 border border-gray-300 rounded-lg text-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="name@gmail.com">
            </div>
            <div class="mb-4">
                <label for="message" class="block flex items-center mb-2 text-md font-medium text-gray-900"><svg
                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-5 h-5 mr-2">
                        <path stroke-linecap="round" stroke-linejoin="round"
                            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                    </svg>
                    Parolni yangilash <span class="font-bold"></span></label>
                <input v-model="data.password" type="password" id="small-input" required
                    class="mb-2 block w-full p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Eski parol">
                <input v-model="data.password" type="password" id="small-input" required
                    class="mb-2 block w-full p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Yangi parol">
                <input v-model="data.author" type="password" id="small-input" required
                    class="mb-2 block w-full p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Parolni tasdiqlash">

            </div>

            <div v-if="data.imageHashId" class="relative inline-block w-full">
                <img :src="getImageByHashId(data.imageHashId)" alt="article-photo" class="w-full h-auto rounded">
                <div
                    class="absolute top-0 left-0 right-0 bottom-0 bg-gray-800 bg-opacity-50 flex items-center justify-center opacity-0 transition-opacity duration-300">
                    <button @click="deleteImage"
                        class="flex text-lg text-white bg-red-500 hover:bg-red-600 rounded px-4 py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                        </svg>
                    </button>
                </div>
            </div>
            <div v-else class="flex items-center justify-center w-full">
                <label for="dropzone-file"
                    class="flex flex-col items-center justify-center w-full h-30 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer  bg-white">
                    <div class="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg aria-hidden="true" class="w-10 h-10 mb-3 text-gray-400" fill="none" stroke="currentColor"
                            viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12">
                            </path>
                        </svg>
                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to
                                upload</span> or drag and drop</p>
                        <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                    </div>
                    <input @change="handleFileUpload" id="dropzone-file" type="file" ref="files" class="hidden" />
                </label>
            </div>
            <br>
            <!-- <div class="mb-6">
                <input v-model="data.deleted" id="link-checkbox" type="checkbox" value="true"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                <label for="link-checkbox" class="ml-2 text-md font-medium text-gray-900">I agree the name is
                    <span class="bg-yellow-400 text-white font-bold px-2">DELETED</span> </label>
            </div> -->

            <div class="space-x-2">
                <button @click="updateAdminData"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mr-2">Saqlash</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/axiosInstance';
import NotificationVue from '@/components/Notification.vue';
export default {
    name: 'CreateArticle',
    components: {
        NotificationVue,
    },
    data() {
        return {
            imageField: null,
            imageId: '',
            data: {},
            isDataValid: false,
            notify: '',
            status: '',
        }
    },
    created() {
        // Get admin data here
        // console.log(this.$route.query.articleId)
        // axios.get(`/api/articles/v1/get/${this.$route.query.articleId}`).then(response => {
        //     this.data = response.data;
        //     console.log(this.data)
        // }).catch(error => console.log(error))
    },
    computed: {
        getImageByHashId() {
            return (id) => {
                const imagePath = `/api/files/v1/get/${id}`
                return axios.defaults.baseURL + imagePath
            }
        }
    },
    methods: {
        handleFileUpload(event) {
            this.imageField = event.target.files[0]

            console.log(this.imageField)

            const formData = new FormData();
            formData.append('file', this.imageField)

            
            this.notificationWarning('Kutilmagan xatolik bor!', 'yellow')
            // axios.post(`/api/files/v1/upload`, formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data'
            //     }
            // }).then(response => {
            //     this.imageId = response.data
            //     this.data.imageHashId = this.imageId
            //     this.imagrURl = `http://localhost:8080/api/files/v1/get/${this.imageId}`
            //     console.log(response.data);
            // }).catch(error => console.log(error))
        },
        updateAdminData(event) {
            //for save button
            event.preventDefault();
            // this.notificationWarning('This Page should be fix!', 'yellow')
            this.notificationWarning('Ma\'lumot saqlanmadi!', 'yellow')
            setTimeout(() => {
                this.$router.push({ name: 'dashboard' })
            }, 1000)
            // if (this.data.imageHashId) {
            //     this.isDataValid = this.data.name.trim() !== '' && this.data.email.trim() !== '' && this.data.desc.trim() !== '' && this.data.author.trim() !== ''
            // } else {
            //     this.notificationWarning('Image Not Found!', 'Red')
            // }
            // if (this.isDataValid) {
            //     axios.put(`/api/articles/v1/${this.data.id}`, this.data).then(response => {
            //         console.log(response.data);
            //         if (response.status == 200) {
            //             this.notificationWarning(`Updated successfully. Result: ${response.status}`, 'green')
            //             console.log(response.data, typeof response.data);
            //             setTimeout(() => {
            //                 this.$router.go(-1)
            //             }, 1000)
            //         } else {
            //             this.notificationWarning(`Something went wrong!`, 'yellow')
            //         }
            //     }).catch(error => console.log(error))
            //     // this.$router.push({ name: 'settings' })
            // } else {
            //     this.notificationWarning('Data isn\'t valid!', 'yellow')
            // }
        },
        // handleFileUpdate(event) {
        //     this.imageField = event.target.files[0]
        //     // this.imageField = this.$refs.imageInput.files[0];

        //     console.log(this.imageField)

        //     const formData = new FormData();
        //     formData.append('hashId', this.imageId)
        //     formData.append('file', this.imageField)

        //     axios.put(`/api/files/v1/update`, formData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         }
        //     }).then(response => {
        //         this.imageId = response.data
        //         // this.imagrURl = `http://localhost:8080/api/files/v1/get/${this.imageId}`
        //         console.log(response.data);
        //     }).catch(error => console.log(error))
        // },

        deleteImage() {
            //for imageField
            axios.delete(`/api/files/v1/delete/${this.data.imageHashId}`);
            this.imageField = null;
            this.data.imageHashId = null
            // this.imagrURl = `http://localhost:8080/api/files/v1/get/${this.imageId}`;
            // this.$router.go(0)
        },

        notificationWarning(text = '', status = '') {
            this.status = status
            this.notify = text
            setTimeout(() => {
                this.notify = ''
            }, 2000);
        },

    },
};
</script>

<style scoped>.relative:hover .absolute {
    opacity: 1;
}</style>