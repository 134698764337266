<template>
    <div>
        <router-view />
        <FooterVue />
    </div>
</template>
  
<script>
import FooterVue from '@/components/pages/Footer.vue';

export default {
    name: 'MainLandingPageVue',
    components: {
        FooterVue,
    }
}
</script>
  
<style>
</style>
  