<template>
    <div class="text-left">
        <h1 class="text-xl font-bold mb-4 text-center">TAKLIFNI TAHRIRLASH</h1>
        <div class="container p-10 w-2/3 space-y-4">
            <div>
                <label for="message" class="block mb-2 text-md font-medium text-gray-900"><span
                        class="font-bold">*{{ nameData.latinName }}</span> ismiga kelgan taklif:</label>
                <textarea v-model="offerData.desc" id="message" rows="4"
                    class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Write your thoughts here..."></textarea>

            </div>

                <div>
                    <label for="countries" class="block mb-2 text-md font-medium text-gray-900">*Taklif holatini tanlang:</label>
                    <select v-model="offerData.status"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option selected>Choose a status of offer</option>
                        <option v-for="status in statuses" :value="status" :key="status.id">{{ status.charAt(0) + status.slice(1).toLowerCase() }}</option>
                    </select>

                </div>
                <div>
                    <input id="link-checkbox" type="checkbox" value="true" v-model="offerData.deleted"
                        class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500">
                    <label for="link-checkbox" class="ml-2 text-md font-medium text-gray-900">I agree the name is
                        <span class="bg-yellow-400 text-white font-bold px-2">DELETED</span> </label>
                </div>
                <div>
                    <button type="submit" @click="updateOfferData"
                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Saqlash</button>
                </div>
        </div>


    </div>
</template>

<script>
import axios from '@/axiosInstance';
export default {
    name: 'UpdateOffer',

    data() {
        return {
            offerData: {},
            nameData: {},
            statuses: ['ACCEPTED', 'INITIAL', "REJECTED"],
            isValidData: false,
        };
    },

    created() {
        this.getOfferData()
    },

    methods: {
        getOfferData() {
            const { id } = this.$route.query
            axios.get(`/api/offers/v1/get/${id}`).then(Response => {
                this.offerData = Response.data;

                axios.get(`/api/names/v1/get/${this.offerData.nameId}`).then(Response => {
                    this.nameData = Response.data
                }).catch(error => console.log(error));
            }).catch(error => console.log(error));
        },
        async updateOfferData(event){
            event.preventDefault();
            
            console.log(this.offerData)
            await axios.put(`/api/offers/v1/${this.offerData.id}`, this.offerData);
            this.$router.go(-1)
        }
    },
};
</script>

<style lang="scss" scoped></style>