<template>
    <div>
        <div class="w-full flex flex-col">
            <h1 class="text-3xl font-bold text-black pb-6">ISMLAR JADVALI</h1>

            <div class="w-full">


                <HeaderTable @search="getResultOfNamesBySearchValue" :linkTo="'createName'"/>
                <div class="bg-white overflow-x-auto">
                    <table class="table-auto w-full bg-white">
                        <thead class="bg-gray-800 text-white">
                            <tr>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">№</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Latin_Name</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Latin_Meaning</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Krill_Name</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Krill_Meaning</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">English_Name</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">English_Meaning</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Gender</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Rukn</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Likes</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Views</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">is_Deleted</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Created_date</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Modified_date</th>
                                <th class="text-left py-3 px-4 uppercase font-semibold text-sm">Actions</th>
                            </tr>
                        </thead>
                        <tbody class="text-gray-700 text-sm">
                            <tr v-for="(name, index) in paginatedNames" :key="index" class="hover:bg-gray-100">
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ index + 1 }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ name.latinName }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4 text-xs">{{ name.latinDesc }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ name.krillName }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4 text-xs">{{ name.krillDesc }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ name.englishName }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4 text-xs">{{ name.englishDesc }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ name.gender }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ name.rukn }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ name.likeCount }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ name.seenCount }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">
                                    <span class="flex flex-inline justify-center items-center">
                                        <div class="h-2.5 w-2.5 rounded-full mr-2"
                                            :class="name.deleted ? 'bg-red-500' : 'bg-green-500'"></div>{{ name.deleted }}
                                    </span>
                                </td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ getCreatedDate(name.createdDate) }}
                                </td>
                                <td class="whitespace-nowrap text-left py-2 px-4">{{ getCreatedDate(name.lastModifiedDate)
                                }}</td>
                                <td class="whitespace-nowrap text-left py-2 px-4">
                                    <span class="flex flex-inline justify-center items-center">
                                        <div @click="editName(name)" role="button"
                                            class="font-medium text-blue-600 dark:text-yellow-500 hover:underline">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </div>
                                        <div role="button"
                                            class="font-medium text-blue-600 dark:text-red-500 hover:underline"
                                            @click="showDeleteModal(name)">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </div>
                                    </span>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <Pagination v-if="names.length > 0 && isSearchEmpty" :list="names" @paginated="getPaginatedList" />
        <DeleteModal :deleteModalShow="isModalShow" :deleteItem="deleteItem" @confirmation="deleteName"
            @cancel="isModalShow = !isModalShow" />
    </div>
</template>

<script>
import DeleteModal from '@/components/DeleteModal.vue';
import Pagination from '@/components/Pagination.vue';
import HeaderTable from '@/components/HeaderTable.vue'
import axios from '@/axiosInstance';
export default {
    name: 'NamesVue',
    components: {
        DeleteModal,
        Pagination,
        HeaderTable,
    },

    data() {
        return {
            names: [],
            paginatedNames: [],
            isModalShow: false,
            deleteItem: {},
            isSearchEmpty : true,
        };
    },
    computed: {
        getCreatedDate() {
            return (time) => {
                const date = new Date(time);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = String(date.getFullYear()).slice(2);
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');

                return `${day}-${month}-${year} ${hours}:${minutes}`;
            }
        }
    },
    created() {
        this.fetchData();
    },

    methods: {
        async fetchData() {
            try {
                const result = await axios.get('/api/names/v1');
                this.names = result.data;

            } catch (error) {
                // Handle the error gracefully, e.g., display an error message
                console.error(error); // Log the error for debugging
            }
        },
        async deleteName(id) {
            try {
                await axios.delete(`/api/names/v1/${id}`);
                this.$router.go(0);
                this.isModalShow = !this.isModalShow;
            } catch (error) {
                console.log(error);
            }

        },
        async getResultOfNamesBySearchValue(value) {
            if (value){
                console.log(value);
                const response = await axios.get(`/api/names/v1/search?search=${value}`);
                this.paginatedNames = response.data;
                this.isSearchEmpty = false;
            } else{
                this.isSearchEmpty = true;
            }
        },
        showDeleteModal(name) {
            this.deleteItem = name;
            this.isModalShow = !this.isModalShow;
        },

        editName(obj) {
            this.$router.push({ name: 'updateName', query: { name: obj } });
        },
        getPaginatedList(list) {
            console.log(list)
            this.paginatedNames = list
            // if (this.names.length > 0) {
            //     console.log(this.names)
            //     console.log(this.paginatedNames)
            // }
        }
    },
};
</script>

<style scoped></style>